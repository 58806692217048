@import 'definitions';

.flex {
    display: flex;
}

.horizontal {
    flex-direction: row;
}

.horizontalResponsive {
    @include device('tablet') {
        flex-direction: column;
    }
}

.vertical {
    flex-direction: column;
}

/* justify-content - desktop */
.justify-content--flex-start {
    justify-content: flex-start;
}
.justify-content--flex-end {
    justify-content: flex-end;
}
.justify-content--center {
    justify-content: center;
}
.justify-content--space-between {
    justify-content: space-between;
}
.justify-content--space-around {
    justify-content: space-around;
}

/* align items - desktop */
.align-items--flex-start {
    align-items: flex-start;
}
.align-items--flex-end {
    align-items: flex-end;
}
.align-items--center {
    align-items: center;
}
.align-items--baseline {
    align-items: baseline;
}
.align-items--stretch {
    align-items: stretch;
}

/* align content - desktop */
.align-content--flex-start {
    align-content: flex-start;
}
.align-content--flex-end {
    align-content: flex-end;
}
.align-content--center {
    align-content: center;
}
.align-content--space-between {
    align-content: space-between;
}
.align-content--space-around {
    align-content: space-around;
}
.align-content--stretch {
    align-content: stretch;
}

/* flex-direction - desktop */
.flex-direction--row {
    flex-direction: row;
}
.flex-direction--column {
    flex-direction: column;
}

/* flex-wrap - desktop */
.flex-wrap--nowrap {
    flex-wrap: nowrap;
}
.flex-wrap--wrap {
    flex-wrap: wrap;
}
.flex-wrap--wrap-reverse {
    flex-wrap: wrap-reverse;
}



@include device('tablet') {
    /* justify-content - mobile */
    .justify-content-mobile--flex-start {
        justify-content: flex-start;
    }
    .justify-content-mobile--flex-end {
        justify-content: flex-end;
    }
    .justify-content-mobile--center {
        justify-content: center;
    }
    .justify-content-mobile--space-between {
        justify-content: space-between;
    }
    .justify-content-mobile--space-around {
        justify-content: space-around;
    }

    /* align items - mobile */
    .align-items-mobile--flex-start {
        align-items: flex-start;
    }
    .align-items-mobile--flex-end {
        align-items: flex-end;
    }
    .align-items-mobile--center {
        align-items: center;
    }
    .align-items-mobile--baseline {
        align-items: baseline;
    }
    .align-items-mobile--stretch {
        align-items: stretch;
    }

    /* align content - mobile */
    .align-content-mobile--flex-start {
        align-content: flex-start;
    }
    .align-content-mobile--flex-end {
        align-content: flex-end;
    }
    .align-content-mobile--center {
        align-content: center;
    }
    .align-content-mobile--space-between {
        align-content: space-between;
    }
    .align-content-mobile--space-around {
        align-content: space-around;
    }
    .align-content-mobile--stretch {
        align-content: stretch;
    }

    /* flex-direction - mobile */
    .flex-direction-mobile--row {
        flex-direction: row;
    }
    .flex-direction-mobile--column {
        flex-direction: column;
    }

    /* flex-wrap - mobile */
    .flex-wrap-mobile--nowrap {
        flex-wrap: nowrap;
    }
    .flex-wrap-mobile--wrap {
        flex-wrap: wrap;
    }
    .flex-wrap-mobile--wrap-reverse {
        flex-wrap: wrap-reverse;
    }
}
