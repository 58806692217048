.description {
    text-align: center;
}

.cards {
    max-width: 75%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 2em auto;
}
