@import 'definitions';

.dropdownAnchor.open {
    position: relative;
    min-height: 50px;
}

.dropdown {
    width: 100%;

    &.open {
        position: absolute;
        z-index: 1;
        box-shadow: 5px 5px 15px 5px rgba(0,0,0,0.3);
    }
}

.dropdownHead {
    display: flex;
    flex-flow: row nowrap;
    cursor: pointer;
    border: 1px solid #cacaca;
    background-color: $white;
    border-radius: 3px;
    padding: 10px;

    &.open {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
}

.title {
    @include --s-paragraph;
    flex: 1;
    user-select: none;
}

.chevron {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 10px;
}

.dropdownBody {
    width: 100%;
    max-height: 300px;
    overflow-y: scroll;
    overflow-x: hidden;
    background-color: $white;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
}
