@import 'namifesti';

$margin-from-edge: 2rem;

.container {
    background: $intohimo-orange;
    color: $intohimo-white;
    padding-bottom: $spacing-4x;

    .bubbles {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-top: -8rem;
        gap: 1rem;

        button {
            display: flex;
            flex-direction: column;
            align-items: center;
            max-width: 230px;
            background: none;
            color: inherit;
            border: none;
            padding: 0;
            font: inherit;
            cursor: pointer;
            outline: inherit;

            .nameWrapper {
                z-index: 999;
            }

            .name {
                max-width: 100%;
                max-height: 40px;
            }
            
            .pt {
                max-width: 100%;
                max-height: 48px;
            }
        }

        button:focus {
            outline: revert;
        }
    }

    .pictureWrapper {
        clip-path: circle(40%);
        max-width: 230px;
        display: flex;
        justify-content: center;
        margin-bottom: -2rem;

        .picture {
            display: flex;
            align-items: center;
            position: relative;

            .arrow {
                position: absolute;
                top: 42%;
                left: 42%;
                z-index: 999;
                width: 40px;
                height: 40px;
            }
        }

        .image {
            scale: 0.8;
        }
    }

    .content {
        .video {
            width: 100%;
            height: auto;
        }

        position: relative;
        display: grid;
        place-items: center;
        margin: 0 $margin-from-edge $spacing-2x;
        padding-top: 12rem;

        @media only screen and (min-width: $breakpoint-desktop) {
            margin: 0 auto;
        }

        h2 {
            text-align: center;
            position: absolute;
            margin: 0 auto;
            left: 0;
            right: 0;
            top: -6.5rem;

            svg {
                margin: -15px;
                height: 15rem;
                width: 90vw;
                max-width: 500px;
            }
        }
    }
}
