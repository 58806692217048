@import "namifesti";

.container {
    background-color: $intohimo-white;
    padding: $spacing-4x 0;
    position: relative;

    h2 {
        svg {
            height: 12rem;
            width: 90vw;
            max-width: 500px;
        }

        margin: 0 auto;
        padding-bottom: $spacing-2x;
    }

    p {
        color: $intohimo-orange;
        font-size: 1rem;
        text-align: center;
        margin: 0 $spacing-2x;
        padding-bottom: $spacing-2x;
    }

    .formContainer {
        display: grid;
        place-items: center;
        min-height: 20rem;
        margin: 0 $spacing-2x;

        form {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;

            label {
                color: $intohimo-orange;
                font-size: 0.8rem;
                display: grid;
                gap: 0.5rem;
                width: 100%;
                max-width: 500px;
                margin-bottom: 1rem;

                input, textarea {
                    font-size: 1rem;
                    padding: 0.5rem;
                    border: 1px solid $intohimo-orange;
                    line-height: 1.5;

                    &:focus {
                        outline: 2px solid $intohimo-lighter-orange;
                        outline-offset: 1px;
                    }
                }

                .inputHasError {
                    border: 1px solid var(--red-50);

                    &:focus {
                        outline: 2px solid var(--status-red);
                        outline-offset: 1px;
                    }
                }

                span {
                    color: var(--status-red);
                    font-style: italic;
                }
            }

            button {
                margin: $spacing-1x auto 0;
            }
        }

        .formSubmitSuccessText {
            font-size: 1.5rem;
            font-style: italic;
        }

        .formSubmitErrorText {
            color: var(--status-red);
        }
    }

    .wineglassSticker {
        right: -2.5rem;
        bottom: -1rem;
        height: 380px;
        width: 10rem;
        position: absolute;

        @media only screen and (min-width: $breakpoint-desktop) {
            bottom: 1rem;
        }
    }

    hr {
        margin: $spacing-2x $spacing-1x 0;
        border: $intohimo-separator-width solid $intohimo-orange;
    }

    @media only screen and (min-width: $breakpoint-desktop) {
        margin: 0 auto;
        max-width: $desktop-max-content-width;

        p, .form {
            margin: 0;
        }

        hr {
            margin: $spacing-2x 0;
        }
    }
}

.honeypotField {
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
    opacity: 0;
}
