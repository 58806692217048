@import 'definitions';

.cardListContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.cardContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    @include device('desktop') {
        justify-content: space-evenly;
    }

    @include device('mobile') {
        margin-bottom: 2rem;
    }
}

.cardWrapper {
    max-width: 306px;

    @include breakpoint('l') {
        width: calc((100% - ( 2 * 3vw )) / 3);
        margin-bottom: 3vw;

        &:last-child:nth-child(5n+2) {
            margin-left: 3vw;
            margin-right: auto;
        }
    }

    @include breakpoint('xl') {
        margin-bottom: 40px;
    }

    @include device('desktop') {
        width: calc((100% - ( 1 * 3vw )) / 2);
        margin-bottom: 3vw;
    }

    @include device('tablet') {
        width: 100% !important;
        margin-bottom: 30px;
    }

    @include device('mobile') {
        div {
            margin: auto;
        }
        width: 100%;
        margin-bottom: 30px;
        max-width: initial;
    } 
}

.widerLinkCard {
    @include device('mobile') {
        width: 95% !important;
        min-height: 220px !important;
    }
}

.noResults{
    width: 100%;
}