@import 'definitions';

.textbox {
    font-family: SkattaSansUI-Regular;
    font-size: 12px;
    font-style: normal;
    font-stretch: normal;
    line-height: 2.33;
    letter-spacing: 1.5px;
    color: $charcoal-grey;
    padding: 1px 4px;
} 

.textAlignCenter {
    text-align: center;
}

.borderFull {
    border: initial;
    border-style: solid;
    border-width: 1px;
    border-radius: 3px;
    border-color: $lighter-grey;
}

.borderDashed {
    border-width: 0 0 1px 0;
    border-style: dashed;
}
