@import 'definitions';

.hourListOuterContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-between;

    @include device('tablet') {
        flex-flow: column nowrap;
    }
}

.hourList__heading {
    border-bottom: 1px dashed rgba(0, 0, 0, 0.4);
    padding-bottom: 1rem;
    width: 100%;
    margin: 24px 0 0 0;
}
