@import 'definitions';
.filterList {
    list-style: none;
    padding: 0;
    margin: 0.5rem 0;
}

.filterList__item {
    display: inline-block;
}

.filterList__item__link {
    font-family: SkattaSansUI-Medium;
    color: $grey-80;
    padding: 0.5rem;
    margin-right: 0.5rem;
}

@media only screen and (max-width: 600px) {
    .filterList {
        display: none;
    }
}

.allProductBrandsHeading {
    margin: 1rem 0;
}
