@import 'definitions';

.smallHeader {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    height: $smallHeaderHeight;
    width: 100%;
    line-height: 0.6rem;
    color: $white;
    background-color: $orange;
    box-sizing: border-box;
    padding: 0 16px;
    z-index: 3;
}

.container {
    display: flex;
}

.shadow {
    box-shadow: 0px 10px 10px -5px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
}

.logo {
    color: $white;
    display: flex;
    align-items: center;
    justify-content: center;
}

.linkIcon {
    cursor: pointer;
    background-color: transparent;
    border: none;
    padding: 0;
    margin: 0;
    color: $white;
    line-height: 0;
}

.noScroll {
    @include breakpoint('header') {
        overflow: visible;
        position: static;
        touch-action: auto;
    }
    overflow: hidden;
    position: relative;
    touch-action: none;
}

.hidden {
    display: none;
}
