@import "definitions";

.modal {
    position: absolute;
    display: flex;
    border: 1px solid rgb(204, 204, 204);
    background: rgb(255, 255, 255);
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
    border-radius: 4px;
    outline: none;
    @include device('desktop') {
        inset: 40px 100px;
    }
    @include device('tablet') {
        inset: 40px 60px;
    }
    @include device('mobile') {
        inset: 15px 5px;
    }
    inset: 40px 200px;
    padding: 0;
}

.modalContainer {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto;
}

.modalCloseButton {
    position: absolute;
    display: flex;
    flex-direction: column;
    @include device('tablet') {
        right: 12px;
    }
    @include device('mobile') {
        right: 10px;
    }
    right: 15px;
    top: 3px;
    z-index: 10;
    cursor: pointer;
    align-items: center;
    background-color: transparent;
    padding: 0;
    border: 0;
    &:hover {
        background-color: transparent;
        color: $orange;
    }
    & svg {
        margin-top: -15px;
    }
}

.iframe {
    display: flex;
    flex-grow: 1;
    border: 0;
}
