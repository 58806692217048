@import "namifesti";

$margin-from-edges: 1rem;

.container {
    background-color: $intohimo-blue;
    padding: $spacing-4x 0;

    h2 {
        text-align: center;
        position: relative;
        padding: 0 0 0.5rem;
        margin: 0 $margin-from-edges;
        border-bottom: $intohimo-separator-width solid $intohimo-orange;

        .bookSticker {
            position: absolute;
            left: 0;
            top: 0;
            height: 5rem;
            width: 5rem;
        }

        .title {
            height: 5rem;
            width: 5rem;
        }

        @media only screen and (min-width: $breakpoint-desktop) {
            margin: 0 auto;
            max-width: $desktop-max-content-width;
        }
    }

    .content {
        h3 {
            padding: 2rem 0 1rem;
            color: $intohimo-light-orange;
            margin: 0 1rem;
            font-family: SkattaSansUI-Bold, sans-serif;
            font-size: 1.5rem;

            @media only screen and (min-width: $breakpoint-desktop) {
                margin: 0;
            }
        }

        @media only screen and (min-width: $breakpoint-desktop) {
            margin: 0 auto;
            max-width: $desktop-max-content-width;
        }
    }
}

.accordion {
    color: $intohimo-white;
    margin: 0 $margin-from-edges;
    border-bottom: $intohimo-separator-width solid $intohimo-orange;

    summary {
        border: none;
        padding: 0.5rem 0;
        margin: 0.5rem 0;

        &:hover {
            color: $intohimo-lighter-orange;
        }

        &:focus-visible {
            outline: 2px solid $intohimo-light-orange;
            outline-offset: 4px;
        }

        h4 {
            text-transform: unset;
            margin: 0;
            padding: 0;
            font-size: 1rem;
            font-family: SkattaSansUI-Bold, sans-serif;
        }
    }

    div {
        padding-bottom: $spacing-2x;

        p {
            font-size: 1rem;
            margin: 0;
        }

        a {
            color: $intohimo-orange;

            &:hover {
                filter: brightness(1.2);
            }
        }
    }

    @media only screen and (min-width: $breakpoint-desktop) {
        margin: 0;
    }
}
