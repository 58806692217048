@import 'definitions';

.container {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 12px;
    line-height: 17px;
}

.loggedIn {
    margin-right: 12px !important;
}

.linkText {
    margin-right: 9px;
    margin-left: 8px;
    cursor: pointer;
    color: $white;
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    @include device('desktop') {
        max-width: 500px;
    }
    @include device('tablet') {
        max-width: 300px;
    }
    @include device('mobile') {
        max-width: 200px;
    }
}
