@import 'definitions';

.contactPersons {
    width: 100%;
}
.contactPersons__heading {
    border-bottom: 1px dashed rgba(0, 0, 0, 0.3);
    padding-bottom: 1rem;
    margin: 24px 0 0 0;
}
.contactPersons__info {
    text-align: left;
}
