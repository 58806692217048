@import 'namifesti';
@import 'definitions';

.container {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 100;
    width: 100%;

    @media only screen and (min-width: $breakpoint-desktop) {
        position: relative;
    }
}

.mobile {
    position: relative;

    &.expanded {
        .content {
            display: flex;
            animation: reveal 0.1s forwards;
            animation-timing-function: linear;

            @keyframes reveal {
                from {
                    clip-path: inset(0 0 0 100%);
                }
                to {
                    clip-path: inset(0 0 0 0);
                }
            }
        }
    }

    &.unexpanded {
        .content {
            display: flex;

            animation: hide 0.1s forwards;
            animation-timing-function: linear;
            @keyframes hide {
                from {
                    clip-path: inset(0 0 0 0);
                }
                to {
                    clip-path: inset(0 0 0 100%);
                    display: none;
                }
            }
        }
    }

    .menu {
        all: unset;
        display: grid;
        place-items: center;
        margin: 0;
        padding: 0.1rem;
        position: absolute;
        z-index: 2;
        top: 1.5rem;
        right: 1.5rem;

        &:hover {
            background: none;
            cursor: pointer;
            opacity: 0.8;
        }

        &:focus-visible {
            &[aria-expanded="false"] {
                outline: 2px solid $intohimo-darker-orange;
            }

            &[aria-expanded="true"] {
                outline: 2px solid $intohimo-white;
            }
        }
    }

    .content {
        display: none;
        flex-direction: column;
        min-height: 100vh;
        background-color: $intohimo-blue;
        color: $intohimo-white;

        .title {
            margin: 0 2rem;
            padding: 1.5rem 0 0 2rem;
            font-family: SkattaSansUI-Bold, sans-serif;
            text-transform: uppercase;
            transform: scaleY(3) skew(-45deg, 0deg);
            font-size: 0.5rem;
        }

        .stickerLinks {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 4rem 0 2rem;

            @media only screen and (min-width: 500px) {
                padding-top: 3rem;
            }

            @media only screen and (min-width: 700px) {
                padding-top: 4rem;
            }

            a {
                display: grid;
                place-items: center;

                &:hover {
                    filter: brightness(2);
                }

                &:focus-visible {
                    outline: 2px solid $intohimo-darker-orange;
                    outline-offset: -10px;
                }
            }

            hr {
                border: none;
                height: 3px;
                background: $intohimo-white;
                width: 80vw;
                max-width: 250px;
            }
        }
    }

    @media only screen and (min-width: $breakpoint-desktop) {
        display: none;
    }
}

.desktop {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem 2rem 0;

    a {
        font-family: SkattaSansUI-Bold, sans-serif;
        transform: scaleY(3) skewX(-25deg);
        font-size: 0.75rem;
        padding: 0.5rem;
        margin: 0 0.5rem;
        text-transform: uppercase;
        letter-spacing: normal;
        text-underline-offset: 2px;

        &:hover {
            color: $intohimo-darker-orange;
        }

        &:focus-visible {
            color: $intohimo-darker-orange;
            outline: 2px solid $intohimo-darker-orange;
        }
    }

    span {
        border-right: 1px solid $intohimo-orange;
        height: 1.5rem;
        width: 1px;
    }

    @media only screen and (max-width: calc($breakpoint-desktop - 1px)) {
        display: none;
    }
}

.namifestiSticker {
    padding-bottom: 1rem;

    svg {
        height: 6rem;
    }
}

.intohimotyoryhmaSticker, .formSticker {
    padding: 0.5rem 0;

    svg {
        height: 5rem;
    }
}

.qaSticker {
    padding-top: 1rem;

    svg {
        height: 4rem;
    }
}
