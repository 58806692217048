
@import 'definitions';
@import '../../components/LandingPage/Hero.scss';

main .homepageContent {
    margin-bottom: 0;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
}

.bodyContainer {
    margin: 0 1rem;

    @include device('tablet') {
        margin: 0;
    }
}

.loading {
    @include --loadingIndicatorWithScroll;
}

.homePageFilterBar {
    width: unset;
    padding-top: 1.5em;
}

.homepageSectionContent {
    margin: 1rem;
}

.instagramFeed {
    width: 100%;
    margin-bottom: 30px;
}
.instagramHeader {
    & > h3 {
        margin: 0 0 0 20px;
    }
    margin: 20px 0;
    @include breakpoint('m') {
        margin: 40px 0;
    }
}
