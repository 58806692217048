@import 'definitions';

.labelNormal {
    text-decoration: none;
    margin: 0 0.5rem 0 0.5rem;

    font-family: SkattaSansUI-Regular;
    font-size: 12px;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: 1.5px;
    text-align: justify;

    text-transform: uppercase;
    cursor: pointer;
}

.black {
    color: $grey-80;
}

.white {
    color: $white;
}

.active {
    color: $orange;
}

.active:after {
    content: "";
    width: 18px;
    border-top: 1px solid $orange;
    display: block;
    margin: 0 auto;
    margin-top: 4px;
    position: relative;
    top: 0;
}
