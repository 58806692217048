/* Footer */
@import 'definitions';

.footer {
    /* Flex needed to declare here for IE10 */
    display: flex;
    padding-top: 40px;
    background-color: $light-grey;
    color: $white;
}

.someAndLanguage {
    display: flex;
    flex-direction: row;
    max-width: $headerFooterContentMaxWidth;
    width: 90%;

    @include device('tablet') {
        flex-direction: column;
        align-items: center;
    }
}

.languageLinks {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    flex: 1;

    @include breakpoint('m') {
        border-left: none;
        padding-left: 15px;
        border-left: dashed 1px rgba(255, 255, 255, 0.30);
    }

    & > a {
        @include --link;
        color: $white;
        margin: 0 10px;

        &:hover {
            color: $white;
        }
    }
}

.contactInfo {
    /* Flex needed to declare here for IE10 */
    display: flex;
    margin: 20px 0 0;
    width: 90%;
    max-width: $headerFooterContentMaxWidth;

    @include device('tablet') {
        margin: 0;
    }
}

.horizontalLineDesktop {
    width: $headerFooterContentMaxWidth;
    @include breakpoint('m') {
        width: 90%;
        border-bottom: dashed 1px rgba(255, 255, 255, 0.30);
        margin: 20px 0 0;
    }
}

.horizontalLineMobile {
    @include device('tablet') {
        width: 100%;
        border-bottom: dashed 1px rgba(255, 255, 255, 0.30);
        margin: 20px 0;
    }
}

.lead {
    color: $white;
    text-shadow: 0 2px 0 rgba(0, 0, 0, 0.35);
    margin-bottom: 20px;
    align-self: stretch;
}

.law {
    @include --s-paragraph;
    background-color: $white;
    color: $grey-80;
    text-align: center;
    margin-top: 20px;
    padding: 40px 0;
    align-self: stretch;

    & > p {
        margin: 0;
    }

}

.link {
    @include --s-paragraph;
    color: $orange;
}

.lawLinks {
    font-size: 16px;
}
