@import 'definitions';

$productSearchResultsMargin: 40px;

.productSearchResult {
    padding: 0 0 $productSearchResultsMargin 0;
}

.loadMoreButton {
    margin-top: calc(#{$productSearchResultsMargin} - 1rem);
}

.loading {
    @include --loadingIndicatorWithScroll;
}
