@import 'definitions';

.status {
    margin-top: 30px;
}

.sending {
    color: blue;
}

.error {
    color: red;
}

.success {
    color: green;
}
