@import 'definitions';

.brandFilter {
    width: 100%;
}

.brandFilter__title {
    @include --filterTitle;
}

.brandFilter__title:hover {
    background-color: transparent;
    cursor: pointer;
}

.brandFilter[data-isdropdownopen='true'] {
    background-color: $lighter-grey2;

    @include device('tablet') {
        background-color: white;
    }
}

.brandFilter[data-isdropdownopen='false'] {
    background-color: transparent;
}

.brandFilter[data-isdropdownopen='true'] > div {
    background-color: $lighter-grey2;

    @include device('tablet') {
        background-color: white;
    }
}

.brandFilter[data-isdropdownopen='false'] > div {
    height: 0px;
    overflow-y: hidden;
}
