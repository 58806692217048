@import 'definitions';

.productList {
    padding: 0 0 0 1rem;
    width: 100%;
    box-sizing: border-box;

    @include device('tablet') {
        padding: 0 0.5rem;
        margin: 0;
        margin-bottom: 2rem;
    }

    @include device('mobile') {
        padding: 0 1rem;
    }
}
