@import 'definitions';
$repeat-count-desktop: 4;
$repeat-count-tablet: 3;
$grid-gap: 16px;

.contentContainer {
    width: 100%;
    position: relative;
    overflow: visible;
    @include device('tablet') {
        width: 100vw;
    }
}

.contentWrapper {
    width: 100%;
    display: grid;
    grid-template-columns: repeat($repeat-count-desktop, calc(100% / $repeat-count-desktop - $grid-gap));
    grid-gap: $grid-gap;
    grid-auto-flow: row;
    grid-auto-columns: 1fr;
    justify-content: center;
    justify-items: center;
    margin-top: 32px;

    @include device('desktop') {
        grid-template-columns: repeat($repeat-count-tablet, calc(100% / $repeat-count-tablet - $grid-gap));
    }
}

.mobileContentWrapper {
    position: relative;
    width: 100%;
    overflow: visible;
    margin-top: 1em;
    max-width: 100vw;
    & .eventCard {
        @include device('mobile') {
            height: 275px;
        }
    }
}

.eventCard {
    height: 100%;
    margin-bottom: 2rem;
    max-width: 300px;
}

.paginationBullet {
    background-color: $orange;
}

.loadMoreContainer {
    justify-content: center;
    display: flex;
    margin-top: 1em;
}

.hideOnTablet {
    @include device('tablet') {
        display: none!important;
    }
}

.hideOnMedium {
    @include breakpoint('m') {
        display: none;
    }
}

h2 {
    margin-top: 25px;

    @include device('tablet') {
        margin-top: 0px;
    }
}

.scrollMargin {
    margin: 0!important;
    display: block;
    position: relative;
    visibility: hidden;
    top: 15px;

    @include device('tablet') {
        top: 30px;
    }
}
