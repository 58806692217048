@import 'definitions';

.ingredientsLayout {
    width: 100%;
    & > * {
        box-sizing: border-box;
        width: $contentWidth;

        @include device('tablet') {
            width: $contentWidthTablet;
        }
    }
}

.ingredientHeading {
    text-align: center;
    margin: 0;
    padding-top: 15px;
}

.ingredientListContainer {
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    margin-top: 20px;
    width: 90%;
    background-color: #F6F6F6;
    align-content: stretch;
    ul {
        width: 100%;
    }
}

.ingredientList {
    width: 100% !important;
}

.ingredientRow {
    width: 90%;
    padding: 16px 16px;
    padding-bottom: 0;
    color: $grey-80;
    hyphens: auto;

    @include device('tablet') {
        padding: 10px 16px;
    }
}

.ingredientRow::after {
    display:block;
    content: "";
    margin: 0 auto;
    position: relative;
    padding-top: 16px;
    border-bottom: 1px solid #c0c0c0;

    @include device('tablet') {
        padding-top: 24px;
    }
}

.ingredientsList {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    list-style-type: none;
    width: 100% !important;
    padding: 0;

    li:last-child:after {
        border-bottom: none;
    }
}

.ingredientFlexContainer {
    display: flex;
    flex: 1;
    width: 100%;
}

.ingredientAmount {
    font-family: SkattaSansUI-Medium;
    width: 25%;
    min-width: 60px;
    padding: 0 0.5rem;
    word-break: break-word;

    @include device('tablet') {
        padding: 0 0.3rem;
    }
}

.ingredientName {
    width: 75%;
    word-wrap: break-word;
    padding: 0 0.5rem;

    @include device('tablet') {
        padding: 0 0.3rem;
    }
}
