@import 'definitions';

.brandTitle {
    margin-bottom: 10px;
}

.linkCardTitle {
    margin-bottom: 25px;

    @include device('tablet') {
        margin-bottom: 10px;
    }
}

.articlesAndRecipesTitle {
    margin-bottom: -10px;

    @include device('tablet') {
        margin-bottom: -9px;
    }
    @include device('mobile') {
        margin-bottom: -8px;
    }
}

.partnerContentContainer {
    padding-top: 50px;

    h1 {
        margin: 20px 0;
    }

    h2, h3, h4 {
        margin: 10px 0;
    }
}

.container {
    @include --searchResultContainer;
}

.contentPageResultContainer {
    margin: 20px 15px 0 15px;

    @include device('tablet') {
        margin: 20px 0 0 0;
    }
}

.brandCards {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    @include breakpoint('l') {
        justify-content: space-evenly;
    }
}

.oneCard, .twoCards {
    @include breakpoint('l') {
        justify-content: flex-start;
    }
}

[id^=brandPageText] {
    margin: auto;
    max-width: 1000px;
}

.noResults {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 10rem;

    h1 {
        font-size: x-large;
    }
}
