@import 'definitions';

.fullWidth {
    width: 100%;
}

.cardContainer {
    align-self: stretch;
    display: flex;
    flex-flow: row wrap;
    justify-content: stretch;
    align-items: stretch;
    margin: 1rem 0;

    @include device('tablet') {
        justify-content: center;
    }
}

.bigCardSection {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: stretch;

    @include breakpoint('l') {
        flex-basis: calc(100%/3);
    }

    @include device('desktop') {
        flex-basis: 50%;
        /* IE10 fix */
        min-width: 290px;
    }

    @include device('mobile') {
        /* iOS fix */
        flex-basis: 100%;
    }
}

.smallCardSection {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: stretch;

    @include breakpoint('l') {
        flex-basis: calc(100%/3);
    }

    @include device('desktop') {
        flex-basis: 50%;
        min-width: 290px;
    }

    @include device('tablet') {
        flex-grow: 1;
    }

    @include device('mobile') {
        flex-basis: 100%;
    }
}


.smallCardContainer {
    @include breakpoint('l') {
        width: calc(100%/3);
    }

    @include device('desktop') {
        width: 50%;
        min-width: 290px;
    }

    @include device('mobile') {
        width: 100%;
    }
}

.arcticlesNoSearchResults {
    width: 100%;
    text-align: center;
}
