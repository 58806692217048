@import "definitions";

.productSearchDescription {
    box-sizing: border-box;
    margin: 0;
    padding: 0 1.5rem 1.5rem 1rem;
    font-size: 1.25rem;
    font-family: SkattaSansUI-Medium;
    width: 100%;

    @include device('tablet') {
        margin: 0;
        padding: 0 0.5rem 1rem 0.5rem;
        text-align: center;
    }
}
