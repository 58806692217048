@import 'definitions';

$title-text-shadow: 0 0 8px;
$idea-inspiration-hero-height: 330px;

.heroContainer {
    height: $idea-inspiration-hero-height;
    position: relative;
    margin-bottom: -30px;
}

.heroImage {
    height: $idea-inspiration-hero-height;
    object-fit: cover;
    width: 100%;
}

.overlay:after {
    content: "";
    background: rgba(0, 0, 0, 0.35);
    position: absolute;
    display: block;
    width: 100%;
    top: 0;
    bottom: 0;
}

.heroTexts {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
}

.heroHeading {
    color: $white;
    text-shadow: $title-text-shadow $black;
    margin: 0;
    max-width: 65%;
    margin-bottom: 30px;

    @include device('tablet') {
        margin-bottom: 18px;
    }
}

.heroLead {
    @include --lead;
    color: $white !important;
    margin: 0;
    padding: 0;
    text-shadow: $title-text-shadow $black;
}
