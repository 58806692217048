@import 'definitions';

.wholesales {
    display: flex;

    @include breakpoint('m') {
        border-left: dashed 1px rgba(255, 255, 255, 0.30);
        padding-left: 1rem;
    }

    @include breakpoint('l') {
        border-left: dashed 1px rgba(255, 255, 255, 0.30);
        padding-left: 4%;
    }

    & .list {
        padding: 0 1rem;
    }
}

.links {
    display: flex;
}

.link {
    @include --s-paragraph;
    text-transform: none;
    color: $white;
}
