@import 'definitions';

.image {
    object-fit: contain;
    max-width: 100%;
    margin: 1rem auto;
    display: block;
    width: auto;
    height: auto;
}

.scrollMargin {
    display: block;
    position: relative;
    visibility: hidden;
    top: -55px;

    @include device('desktop') {
        top: -65px;
    }
    @include device('tablet') {
        top: -10px;
    }
}

.container {
    &.left {
        p {
            text-align: left;
        }
    }
    &.centered {
        display: flex;
        flex-direction: column;
        align-items: center;

        h2, h3, h4, h5, h6, > p {
            text-align: center;
        }
    }

    p {
        margin: 0 0 10px;
    }

    h2 {
        margin: 0 0 30px 0;
        &:last-child {
            margin: 0;
        }
    }
    h3 {
        margin-bottom: 20px;
    }
    padding: 3%;
    &.onlyHeading2 {
        padding: 0% 3%;
    }
    &.smallerMarginsOnTitles {
        padding: 0;
        h1, h2, h3, h4, h5, h6 {
            margin: 24px 0 0px 0;
        }
    }

    flex: 1 1;
    max-width: 100%; // This is an IE fix which does nothing on other browsers
}
