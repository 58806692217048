@import "namifesti";

$margin-from-edge: 2rem;

.container {
    background: $intohimo-orange;
    color: $intohimo-white;
    padding-bottom: 6rem;

    .hero {
        display: grid;
        place-items: center;
        color: black;
        border: var(--dev-border);
        background: $intohimo-white;

        img {
            height: auto;
            width: 100%;

            @media only screen and (min-width: $breakpoint-desktop) {
                max-width: $desktop-max-content-width;
            }
        }
    }


    .content {
        position: relative;
        display: grid;
        place-items: center;
        margin: 0 $margin-from-edge $spacing-2x;
        padding-top: 12rem;

        @media only screen and (min-width: $breakpoint-desktop) {
            max-width: $desktop-max-content-width;
            margin: 0 auto;
        }

        h2 {
            text-align: center;
            position: absolute;
            margin: 0 auto;
            left: 0;
            right: 0;
            top: -6rem;

            svg {
                height: 15rem;
                width: 90vw;
                max-width: 500px;
            }
        }

        p {
            font-family: SkattaSansUI-Regular, sans-serif;
            margin: 0;
            padding: 0;
            text-align: center;
        }
    }

    .buttonContainer {
        width: fit-content;
        margin: $spacing-2x auto 0;
    }
}
