@import 'definitions';

.attributeBoxContainer{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    height: 100%;
    margin-bottom: 10px !important;

    section {
        margin: 0px 10px;
    }
}

.attributeBox {
    display: flex;
    flex-direction: column;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    justify-content: space-between;
    p {
        text-align: center;
        margin-top: 0px;
        color: $grey-80;
    }

    a {
        text-align: center;
        margin-top: 0px;
        color: $orange;
    }

    @include device('tablet') {
        margin-bottom: 15px;
    }
}

.header {
    text-transform: uppercase;
    font-size: 15px;
    color: $black;
    text-align: center;
    margin: 0;
}