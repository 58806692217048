@import 'definitions';

.form {
    width: 100%;
    margin-top: 0;
}

.textareaContainer {
    width: 100%;
    margin-top: 20px;
}

.textareaContainer textarea {
    width: 100%;
    height: 200px;
}

.contactInput {
    margin-bottom: 25px;
}

.contactInput input {
    width: 250px;
}

.instructions {
    font-size: 1rem;
    margin: 40px 0 20px 0;
    text-align: center;
}

.hidden {
    display: none !important;
}