@import 'definitions';

.form {
    width: 100%;
    margin-top: 30px;
}

.contactInput {
    margin: 0 0 25px 0;
}

.contactInput input {
    font-size: 16px;
    box-sizing: border-box;
    min-width: 320px;
    width: 100%;
}

.contactTextArea {
    width: 100%;
    margin-bottom: 2rem;
}

.contactTextArea textarea {
    font-size: 16px;
    width: 100%;
}

.formBody {
    display: grid;
    column-gap: 4rem;

    @include breakpoint("m") {
        grid-template-columns: repeat(2, 1fr);
    }
}

.hidden {
    display: none;
}

.formCaptureError {
    display: grid;
    place-items: center;
    text-align: center;
    color: $faded-red;
    min-height: 5rem;
}
