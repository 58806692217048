@import 'definitions';

.infoBoxContainer {
    text-align: center;
    margin-top: 2rem;
    div:nth-child(1) {
        margin-right: 0.5rem;
    }
    div:nth-child(2) {
        margin-left: 0.5rem;
    }
    @include device('mobile') {
        flex-wrap: wrap;
        div:nth-child(1) {
            margin: 0.5rem 0;
        }
        div:nth-child(2) {
            margin: 0.5rem 0;
        }
    }
}

.infoBox {
    flex-basis: 0;
    flex-grow: 1;
    background-color: #fafafa;
    padding: 1.5rem;
    p {
        margin: 0;
        margin-bottom: auto;
    }
}

.infoBoxLink {
    color: #E7702D;
    border: 1px solid #E7702D;
    border-radius: 30px;
    padding: 0.5rem 0;
    margin-top: 1rem;
}
