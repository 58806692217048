@import 'definitions';

.modal {
    display: block;
    box-shadow: 5px 5px 15px 5px rgba(0,0,0,0.3);
    z-index: 99999;
    background: $white;
    width: 100%;
    position: absolute;
    padding: 32px;
    left: 0;
    right: 0;
    margin: 5% auto;
    border: 1px solid $orange;
    border-radius: 4px;
    max-width: 500px;
    @include device('tablet') {
        max-width: 200px;
    }
}

.row {
    margin: 10px;
    justify-content: right;
    float: right;
}

.button {
    justify-content: center;
    display: flex;
    margin-left: 30px;
    padding: 12px;
    border-radius: 4px;
    width: 100px;
    cursor: pointer;
    border: 1px $orange solid;
    &:hover {
        background-color: $orange;
        color: $white;
    }
    @include device('tablet') {
        width: 60px;
    }
}

