@import 'definitions';

$quotemarkPadding: 30px;


@mixin --quoteMark {
    font-size: calc(72rem / 18);
    line-height: 0.5;
    letter-spacing: 1.8px;
    color: $grey-80;
    display: block;
    margin: 0.1em 0.1em -0.5em 0.1em;
}

.markdownPresenter {
    align-self: stretch;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    color: $grey-80;

    & > * {
        box-sizing: border-box;
        width: $contentWidth;

        @include device('tablet') {
            width: $contentWidthTablet;
        }
    }

    & > h1 {
        max-width: none;
        width: auto;
    }

    & > p > picture > img,
    & > p > img {
        align-self: center;
        max-width: 100%;
        margin: 0 auto;
    }

    & > p {
        flex-shrink: 0; /* fixes ie bug */
    }

    & > blockquote {
        @include --lead;
        padding: 1em;
        margin: 40px 0 0 0;
        border-top-style: dotted;
        border-bottom-style: dotted;
        max-width: 68%;
        padding-left: $quotemarkPadding;
        padding-right: $quotemarkPadding;

        @include device('tablet') {
            max-width: 86.5%;
        }
    }

    & > h2 {
        max-width: 85%;
    }

    & > ul {
        margin: 40px 0 0 0;

        @include device('tablet') {
            margin-top: 20px;
        }
    }

    & > ol {
        margin: 40px 0 0 0;

        @include device('tablet') {
            margin-top: 20px;
        }
    }

    & > table {
        margin-top: 30px;
    }

    & > table th, & > table td {
        @include --s-paragraph;
        padding: 0 10px 0 0;
        vertical-align: top;
        text-align: end;
    }

    & > table td:first-child, & > table th:first-child {
        text-align: start;
    }

    .image {
        @include device('tablet') {
            width: 100%;
        }
    }
}

.markdownPresenter blockquote p {
    color: $grey-80;
    width: 100%;
    text-align: center;
    position: relative;
    margin: 0;

    @include breakpoint('m') {
        &:before {
            @include --quoteMark;
            content: '“';
            position: absolute;
            left: calc(-1 * #{$quotemarkPadding});
            top: 50%;
            bottom: 50%;
        }

        &:after {
            content: '”';
            @include --quoteMark;
            position: absolute;
            right: calc(-1 * #{$quotemarkPadding});
            top: 50%;
            bottom: 50%;
        }
    }
}
