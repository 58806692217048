$dev-border: 2px dotted red;
$focus-outline-color: #f4b788;

$intohimo-blue: #1a1460;
$intohimo-darker-orange: #CB4700;
$intohimo-orange: rgb(248, 104, 0);
$intohimo-white: white;
$intohimo-lighter-orange: rgb(246 209 137);
$intohimo-light-orange: rgb(241 191 77);
$intohimo-separator-width: 3px;

$breakpoint-tablet: 768px;
$breakpoint-desktop: 1024px;

$desktop-max-content-width: 700px;
$space-between-elements: 2rem;

$spacing-1x: 1rem;
$spacing-2x: 2rem;
$spacing-4x: 4rem;
$spacing-6x: 6rem;
$spacing-8x: 8rem;