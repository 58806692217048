@import 'definitions';

.searchTypeSelect {
    display: flex;
    justify-content: space-between;
    overflow-x: auto;
    scrollbar-width: none;
    padding: 0.5rem 0;
}

.searchTypeSelect::-webkit-scrollbar {
    display: none;
}

.item {
    color: $bubble-grey;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    padding: 0.25rem;
    margin: 0 0.5rem;
}

/** Ensure that the parent container's width always have 1rem extra,
this is to ensure that keyboard navigation outline is shown properly. */
.item:first-child {
    margin-left: 0.5rem;
}
.item:last-child {
    margin-right: 0.5rem;
}

.item:focus-visible {
    outline: 2px solid $accessibility-kb-focus;
    outline-offset: 0.25rem;
}

.active {
    border-bottom: 2px solid $orange;
    color: $grey-80;
    font-weight: bold;
}
