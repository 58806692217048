@import "definitions";

.container {
    display: grid;
    place-items: center;
    margin: 2rem 0;

    h2 {
        margin-top: 0;
    }

    a {
        color: $darker-orange;
    }

    .alternativeLink {
        margin-top: 1rem;
    }

    .content {
        width: fit-content;
        display: grid;
        place-items: center;
        border: 2px solid $charcoal-grey;
        background-color: $lighter-grey2;
        border-radius: 0.5rem;
        padding: 3rem 2rem;
        text-align: center;
    }
}
