.container {
    width: 90%;
}

.heading {
    margin-bottom: 24px;
}

.instructions {
    margin: 40px 0 20px 0;
    text-align: center;
}

.navItem {
    flex: 1;
    text-align: center !important;
}

.navItem:hover {
    color: inherit;
}
