@import 'definitions';

.textarea {
    font-family: SkattaSansUI-Regular;
    font-size: 18px;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: 0.4px;
    text-align: left;
    color: $grey-80;

    border-radius: 3px;
    min-height: 120px;

    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;

    padding: 2px 4px;

    @include device('tablet') {
        min-height: 80px;
    }
}

.borderFull {
    border: initial;
    border-style: solid;
    border-width: 1px;
    border-radius: 3px;
    border-color: $lighter-grey;
}

.borderDashed {
    border: dashed 1px rgba(49, 49, 61, 0.39);
}

.centerWithPadding {
    padding: 40px 150px 40px 150px;

    @include device('tablet') {
        padding: 20px;
    }
}

