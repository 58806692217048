@import "namifesti";

.container {
    all: unset;
    font-family: SkattaSansUI-Bold, sans-serif;
    text-transform: uppercase;
    font-size: 1rem;
    padding: 0.2rem 0.4rem;
    cursor: pointer;

    &:disabled {
        opacity: 0.6;
        cursor: not-allowed;
    }
}

.colorWhiteOnOrange {
    background: $intohimo-orange;
    color: $intohimo-white;

    &:hover {
        background: $intohimo-darker-orange;
    }

    &:focus-visible {
        outline: 2px solid $intohimo-darker-orange;
        outline-offset: 5px;
    }
}

.colorOrangeOnWhite {
    background: $intohimo-white;
    color: $intohimo-orange;

    &:hover {
        color: $intohimo-darker-orange;
        background: $intohimo-white;
    }

    &:focus-visible {
        outline: 2px solid $intohimo-white;
        outline-offset: 5px;
    }
}
