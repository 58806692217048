@import "namifesti";

.container {
    padding: $spacing-1x 0 $spacing-4x;

    .links {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        margin: 0 auto;
        max-width: 90vw;

        button {
            all: unset;
        }

        a, button {
            color: $intohimo-orange;
            font-family: SkattaSansUI-Bold, sans-serif;
            font-size: 0.75rem;
            padding: 0.25rem;

            &:focus-visible {
                color: $intohimo-darker-orange;
                outline: 2px solid $intohimo-darker-orange;
            }

            &:hover {
                color: $intohimo-darker-orange;
                cursor: pointer;
            }
        }

        span {
            border-right: 2px solid $intohimo-orange;
            height: 1rem;
            margin: 0.5rem;
        }
    }

    p {
        display: flex;
        justify-content: center;
        width: 100%;
        text-align: center;
        color: $intohimo-orange;
        font-family: SkattaSansUI-Bold, 'sans-serif';
        font-size: 1rem;
        margin-top: $spacing-1x;
        text-transform: uppercase;
    }

    .kesproLogo {
        transform: scale(1.5);
        padding-left: 1rem;
    }
}
