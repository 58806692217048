.productList {
    margin: 0;
    width: 100%;
    max-width: unset;
}

.title {
    margin-bottom: 1rem;
}

.centerProducts {
    justify-content: center;
}

.notFoundText {
    text-align: center;
}
