@import 'definitions';

.textWithIcon {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.25rem 0;

    &[href]:hover {
        color: $orange;
    }

    a {
        color: currentColor;
        &:hover {
            color: $orange;
        }
    }

    svg {
        color: $orange;
        height: 1.2rem !important;
        width: 1.2rem !important;
    }

    &.reversed {
        flex-direction: row-reverse;
        justify-content: flex-end;
    }
}