@import 'definitions';

.shareButton {
    all: unset;
    cursor: pointer;
    &:focus {
        outline: revert;
        outline-offset: 0.5rem;
        outline-color: $darker-orange;
    }
    &:hover {
        background: none;
        color: $darker-orange;
    }
}
