@import 'definitions';

.contentWrapper {
	width: 100%;
}

.content {
    justify-content: center;
	display: flex;
}
