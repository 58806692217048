@import 'definitions';

.hideOnMobile {
    @include device('tablet') {
        display: none !important;
    }
}

.showOnlyOnMobile {
    @include breakpoint('m') {
        display: none !important;
    }
}
