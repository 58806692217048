@import "definitions";

.container {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    justify-content: center;
}

.chip {
    font-family: SkattaSansUI-Medium;
    display: flex;
    align-items: center;
    white-space: pre;
    border: 1px solid $charcoal-grey;
    border-radius: 1rem;
    padding: 0.1rem 1rem;
    font-size: 0.8rem;

    @include breakpoint('m') {
        &:hover {
            background-color: $white;
            color: $orange;
            border-color: $orange;
            cursor: pointer;
         }
    }


    &:has(input:focus-visible) {
        outline: 2px solid $accessibility-kb-focus;
        outline-offset: 2px;
    }

    &[data-selected="true"] {
        background-color: $orange;
        border-color: $orange;
        color: $white;
    }

    input {
        height: 0;
        width: 0;
        opacity: 0;
    }
}

.chipLarge {
    font-size: 16px;
    border-radius: 1.5rem;
    padding: 0.5rem 1rem;
}
