@import 'definitions';

.productSearchResults__filters__container {
    & > * {
        min-width: 279px;
        max-width: 279px;
        padding: 0.25rem 0.5rem;

        @include device('tablet') {
            max-width: unset;
            min-width: unset;
            width: auto !important;
        }
    }

    & > *:not(:last-child) {
        margin-bottom: 1rem;
    }

    @include device('tablet') {
        overflow-y: scroll;
        margin-bottom: 0 !important;
    }
}

.sideCategory {
    @media only screen and (max-width: 768px) {
        display: none;
    }
}

.filterUnderSearchBar {
    margin-top: 18px;
    @media only screen and (min-width: 769px) {
        display: none;
        margin-top: 0px;
    }
}