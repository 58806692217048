@import 'definitions';

.hoursListContainer {
    width: 100%;

    .openHoursToday {
        text-align: center;
        margin: 0;
    }
}

.hoursList {
    padding: 0;
}

.hoursListHeader {
    margin-top: 15px;

    &.card {
        @include --s-paragraph;
        text-align: center;
    }

    &.detail {
        font-family: SkattaSansUI-Regular;
        text-align: center;
    }
}

.hoursListItem {
    display: flex;
    flex-flow: row nowrap;

    &.card {
        @include --s-paragraph;
        display: flex;
        justify-content: center;

        & > * {
            margin: 0 0.25rem;

            &:first-child {
                width: 3rem;
                text-align: center;
            }
        }
    }

    &.detail {
        @include --paragraph;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        padding: 0.1rem;
        border-bottom: 1px dashed rgba(0, 0, 0, 0.4);

        &[data-columns="3"] {
            grid-template-columns: repeat(3, 1fr);
            @include device('mobile') {
                grid-template-columns: repeat(2, 1fr);
            }
        }

        .description {
            @include device('mobile') {
                grid-row-start: 2;
                grid-column-start: 1;
                grid-column-end: span 2;
            }
        }

        .label {
            width: 13ch;
        }

        .time {
            text-align: right;
        }
    }
}
