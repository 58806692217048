@import 'definitions';

.methodLayout {
    & > * {
        box-sizing: border-box;
        width: $contentWidth;

        @include device('tablet') {
            width: $contentWidthTablet;
        }
    }
}

.horizontalLine {
    margin-top: 20px;
    max-height: 10px;
}

.steps {
    width: 100% !important;
    align-items: center;
    word-break: break-word;

    h3 {
        text-align: center;
    }
}

.step {
    width: 100% !important;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: stretch;
    margin-top: 1.5rem;

    & > p {
        margin: 0 1.5rem;
    }
}
