/* Common */
@import 'definitions';

:root {
    /* Declarations */
    font-size: 18px;
}

* {
    flex-shrink: 1;
}

body {
    @include --paragraph;
    color: $grey-80;
}

p {
    font-weight: initial;
    font-family: SkattaSansUI-Regular;
    font-size: 16px;
    margin: 40px 0 0 0;

    @include device('tablet') {
        margin-top: 20px;
    }
}

h1 {
    font-weight: initial;
    font-family: SkattaSansUI-Black;
    font-size: 40px;
    text-align: center;
    line-height: 1.15em;
    margin: 60px 0 25px 0;

    @include device('tablet') {
        margin-top: 40px;
        font-size: 24px;
    }
}

h2 {
    @include --h2Text;
    margin: 60px 0 0 0;
    @include device('tablet') {
        margin-top: 40px;
    }
}

h3 {
    font-weight: initial;
    font-family: SkattaSansUI-Medium;
    font-size: 18px;
    letter-spacing: calc(1.2rem / 18);
    text-align: left;
    line-height: 1.5rem;
    margin: 40px 0 0 0;

    @include device('tablet') {
        margin-top: 20px;
    }
}

h4 {
    font-weight: initial;
    font-family: SkattaSansUI-Medium;
    font-size: 16px;
    line-height: 2.33;
    letter-spacing: calc(1.5rem / 18);
    text-align: left;
    text-transform: uppercase;
    margin: 40px 0 0 0;

    @include device('tablet') {
        margin-top: 20px;
        font-size: 14px;
    }
}

a {
    font-weight: initial;
    text-decoration: none;
    color: $orange;
}

button {
    @include --button;
}
