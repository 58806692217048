@import "namifesti";

.overflowContainer {
    position: relative;
    padding-bottom: 4rem;

    .mouthSticker {
        position: absolute;
        bottom: -2rem;
        right: -4rem;
        height: 347px;
        width: 240px;
    }

}

.container {
    position: relative;
    border: var(--dev-border);
    display: grid;
    place-items: center;
    min-height: 100vh;
    overflow: clip;

    @media only screen and (min-width: $breakpoint-desktop) {
        min-height: 80vh;
    }

    .curlyBackground {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        max-height: 100vh;
        transform: scale(3);

        @media only screen and (min-width: $breakpoint-desktop) {
            display: none;
        }
    }

    .videoContainer {
        clip-path: inset(8rem 43% 4rem 43%);
        position: absolute;
        display: grid;
        place-items: center;

        video {
            max-height: 100vh;
        }

        @media only screen and (min-width: 375px) {
            clip-path: inset(8rem 42% 4rem 42%);
        }

        @media only screen and (min-width: 425px) {
            clip-path: inset(8rem 41% 4rem 41%);
        }

        @media only screen and (min-width: 550px) {
            clip-path: inset(8rem 38% 4rem 38%);
        }

        @media only screen and (min-width: 700px) {
            clip-path: inset(8rem 35% 4rem 35%);
        }

        @media only screen and (min-width: $breakpoint-desktop) {
            clip-path: none;
        }

        @media only screen and (min-width: $breakpoint-desktop) {
            padding-top: 10rem;
            video {
                max-height: unset;
                max-width: 1000px;
            }
        }
    }

    h1 {
        all: unset;
        position: absolute;
        top: 0;
        margin-top: 2rem;

        svg {
            height: 269px;
            width: 90vw;
            max-width: 500px;
        }

        @media only screen and (min-width: $breakpoint-desktop) {
            top: -2em;
        }
    }
}
