@import 'definitions';

$content-padding: 0.25rem 0.5rem;

.contactCard {
    border: 1px solid #c0c0c0;
    border-radius: 0.5rem;
    color: $grey-80;
    overflow: hidden;
    position: relative;

    @include breakpoint('sm') {
        height: 100%;
    }

    .content {
        font-family: SkattaSansUI-Regular, 'sans-serif';
        font-size: 16px;
        padding: $content-padding;
        display: none;

        &[aria-expanded="true"] {
            display: block;
        }

        .linkToFillContainer {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
        }

        .linkInDesktop {
            color: $grey-80;
            position: relative;
            z-index: 2;

            &:hover {
                color: $orange;
            }
        }

        @include breakpoint('sm') {
            display: block;

            &.mobileOnly {
                display: none;
            }

            &.desktopOnly {
                display: block;
            }
        }
    }
}


.header {
    background-color: #f0f0f0;
    width: 100%;

    @include breakpoint('sm') {
        pointer-events: none;
    }

    h3 {
        font-family: SkattaSansUI-Regular, sans-serif;
        font-size: unset;
        display: flex;
        flex-direction: column;
        margin: 0;
    }

    .icon {
        background-color: $white;
        color: $orange;
        border-radius: 50%;
        display: grid;
        place-items: center;
        padding: 0.5rem;
    }

    .iconHideInMobile {
        @include device('mobile') {
            display: none;
        }
    }

    .name {
        font-family: SkattaSansUI-Medium;
        font-size: 18px;
    }

    .title {
        font-size: 14px;
        text-transform: uppercase;
    }

    .headerContent {
        display: flex;
        justify-content: space-between;
        padding: 0.25rem 0.5rem 0.5rem 0.5rem;

        .right {
            display: flex;
            align-items: center;
            gap: 1rem;

            @include breakpoint('sm') {
                height: fit-content;
                padding: 0.25rem;
            }

            .expandIcon {
                color: $orange;

                @include breakpoint('sm') {
                    display: none;
                }
            }
        }
    }
}

.wholesaleLocationCard {
    color: currentColor;

    &:hover {
        cursor: pointer;
    }

    .header {
        background-color: #fcf0e1;
    }

    .link {
        &:hover {
            color: $orange;
        }
        svg {
            color: currentColor;
        }
    }
}

.linkColor {
    color: $grey-80;
}

.salesType {
    font-family: SkattaSansUI-Medium;
    background-color: $blue-80;
    border-radius: 1rem;
    color: $white;
    font-size: 12px;
    padding: 0.15rem 0.4rem;
    margin-top: 4px;
    float: left;
}

.salesTypehidden {
    background-color: transparent !important;
}

.emailButton {
    margin: 5px 0px;
    max-height: 30px;
    padding: 0 15px !important;
}

.emailButtonContent {
    font-size: 14px;
 
}