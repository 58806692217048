@import 'definitions';
@import 'common';

.sticky {
	position: sticky;
}

.navBar {
	margin-left: 0 !important;
	margin-right: 0 !important;
	max-width: 100% !important;
    top: 0px;
	align-self: flex-start;
	width: 100%;
    padding: 0 25px;
	min-height: 50px;
    box-sizing: border-box;
    display: flex;
	align-items: center;
    flex-flow: row nowrap;
    justify-content: space-between;
    background-color: white;
    /* Combined distance of 30px from the header.
     * Padding is used to create a gap to the top of the viewport when the header sticks.
     */
    margin-top: 20px;
    padding-top: 10px;
    z-index: 5;

    @include device('tablet') {
        position: static;
        flex-flow: column nowrap;
        justify-content: flex-start;
        align-items: center;
		padding-top: 0;
    }
}

.heroContainer {
    position: relative;
	width: 100%;
	display: block;
	max-height: 600px;
	margin-left: 0 !important;
	margin-right: 0 !important;
	max-width: 100% !important;

	img {
		width: 100%;
		height: 100%;
		max-height: 600px;
		object-fit: cover;
		display: block;
	}
}

.heroButtonContainer {
	display: flex;
	justify-content: center;
	
	button {
		padding: 20px 25px;
	}

	@include breakpoint('m') {
		display: none;
	}
}

.imageContainer {
	width: 100%;
	height: 100%;
}

$narrow-hero-height: 400px;
$narrow-hero-height-tablet: 300px;
$narrow-hero-height-mobile: 200px;

$narrower-hero-height: 300px;
$narrower-hero-height-tablet: 250px;
$narrower-hero-height-mobile: 200px;

.narrow {
	height: $narrow-hero-height;
	@include device('tablet') {
		height: $narrow-hero-height-tablet;
	}
	@include device('mobile') {
        height: $narrow-hero-height-mobile;
    }
}

.narrower {
	height: $narrower-hero-height;
	@include device('tablet') {
		height: $narrower-hero-height-tablet;
	}
	@include device('mobile') {
        height: $narrower-hero-height-mobile;
    }
}

.heroContentContainer {
	font-family: SkattaSansUI-Medium ;
	$lead-text-shadow: 0 0 8px;
	$title-text-shadow: 0 0 8px;
	position: absolute;
	top: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	padding-bottom: 1rem;
	height: calc(100% - 1rem);
	width: 100%;
	h1,h2,p,button{
		&:not(:last-child) {
			margin-bottom: 1rem;
		}
		@include breakpoint('m') {
			max-width: 67%;
		}
	}

	button {
		margin-top: 1.2rem;
	}

	&.narrowerContent {
		.lead {
			font-size: 32px;
			font-weight: 700;
		}
	}
	.lead {
		font-size: 18px;
		text-shadow: $lead-text-shadow $black;
	}

	.headingh1 {
		@extend h1;
		text-shadow: $title-text-shadow $black;
		font-size: 44px !important;

		@include device('tablet') {
			font-size: 24px !important;
		}
	}

	.headingh2 {
		@extend h2;
		font-size: 44px !important;
		text-shadow: $title-text-shadow $grey-80;

		@include device('tablet') {
			font-size: 20px !important;
		}
	}

	&.black {
		color: $grey-80;
		background: linear-gradient(to top, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 65%);
		.lead {
			text-shadow: $lead-text-shadow $white;
		}
		.headingh1, .headingh2 {
			text-shadow: $title-text-shadow $white;
		}
	}

	&.orange {
		color: $orange;
		background: linear-gradient(to top, rgba(0,0,0,0.4) 0%, rgba(0,0,0,0) 65%);
	}

	&.white {
		color: $white;
		background: linear-gradient(to top, rgba(0,0,0,0.4) 0%, rgba(0,0,0,0) 65%);
	}

	button {
		@include breakpoint('m') {
			min-width: 200px;
		}
		&.hidden {
			display: none;
		}
	}
}

.contentContainer {
	p {
		font-family: SkattaSansUI-Medium;
	}

	.lead {
		font-size: 18px;
		text-align: center;
		margin: 0 50px;
		@include device('tablet') {
			margin: 0 25px;
		}
	}
}

.hideOnLarge {
	@include breakpoint('m') {
		display: none;
	}
}

.hideOnMobile{
	@include device('tablet') {
        display: none !important;
    }
}

.smallerKButton {
	display: flex;
	justify-content: center;
	div[class^="KButton"] {
		@include --kButton;
		display: flex;
		flex-direction: row;
		justify-content: center;
		font-size: 14px;
	}
}
