@import 'definitions';

.categoryBreadcrumbs {
    display: flex;
    align-self: flex-start;
    ul {
        display: flex;
        flex-wrap: wrap;
        list-style: none;
        align-items: center;
        padding: 0;
    }
    li {
        display: flex;
        font-size: 16px;
        align-items: center;
        white-space: nowrap;
        &:not(:first-child) {
            &::before {
                content: url('/assets/images/chevron-right-grey.svg');
                width: 22px;
                height: 22px;
                margin-right: 2px;
            }
        }
    }
}

.categoryName:first-letter {
    text-transform: capitalize;
}
