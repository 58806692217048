@import 'definitions';

.notFound {
    display: flex;
    flex-direction: column;
    align-items: center;

    & > * {
        box-sizing: border-box;
        width: $contentWidth;

        @include device('tablet') {
            width: $contentWidthTablet;
        }
    }
}
