/* Fonts */
@font-face {
  font-family: SkattaSansUI-Regular;
  src: local('SkattaSans'), url('/assets/fonts/SkattaSansUI_TT-Regular.woff2') format('woff2');
}

@font-face {
  font-family: SkattaSansUI-Medium;
  src: local('SkattaSans'), url('/assets/fonts/SkattaSansUI_TT-Medium.woff2') format('woff2');
}

@font-face {
  font-family: SkattaSansUI-Bold;
  src: local('SkattaSans'), url('/assets/fonts/SkattaSansUI_TT-Bold.woff2') format('woff2');
}

@font-face {
  font-family: SkattaSansUI-Black;
  src: local('SkattaSans'), url('/assets/fonts/SkattaSansUI_TT-Black.woff2') format('woff2');
}

@font-face {
  font-family: "SkattaSans";
  src: local('SkattaSans'), url('/assets/fonts/SkattaSansUI_TT-Regular.woff2') format('woff2');
  font-weight: 400;
}

@font-face {
  font-family: "SkattaSans";
  src: local('SkattaSans'), url('/assets/fonts/SkattaSansUI_TT-Medium.woff2') format('woff2');
  font-weight: 500;
}

@font-face {
  font-family: "SkattaSans";
  src: local('SkattaSans'), url('/assets/fonts/SkattaSansUI_TT-Bold.woff2') format('woff2');
  font-weight: 700;
}

@font-face {
  font-family: "SkattaSans";
  src: local('SkattaSans'), url('/assets/fonts/SkattaSansUI_TT-Black.woff2') format('woff2');
  font-weight: 900;
}