@import 'definitions';

.headerContainer {
    position: relative;
    width: 100%;
}

.header {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-end;
    align-items: center;
    overflow: hidden;

    @include breakpoint('m') {
        color: $white;
        background: linear-gradient(to top, #000, rgba(0,0,0,0.4) 10%, rgba(0,0,0,0.01) 65%);
    }

    @include device('tablet') {
        color: $grey-80;
        position: relative;
        background-color: none;
    }

}

.altHeader {
    @extend .header;
    @include device('tablet') {
        position: absolute;
        background: linear-gradient(to top, #000, rgba(0,0,0,0.4) 10%, rgba(0,0,0,0.1) 85%);
        color: $white;
    }
}

.headingBar {
    border-top: 1px solid white;
    width: 87%;
    padding: 0.5rem 0 1rem 0;

    @include device('tablet') {
        display: none;
    }
}

.heroImageContainer {
    height: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.heroImage {
    max-width: 1440px;
    width: 100%;
    height: auto;
}

