@import 'definitions';

.heading {
    color: $white;
    max-width: 55%;

    @include device('tablet') {
        max-width: 70%;
    }

    @include device('tablet') {
        color: $grey-80;
    }

}
