@import 'definitions';

.card {
    width: 300px;
    margin: 0.5rem;
    position: relative;
    transition: all .1s ease-in;

    &:hover {
        background-color: $white;
        box-shadow: 0 3px 10px 0 rgba(0, 0, 0, .50);
    }
}

.info {
    width: 100%;
    padding-top: 3rem;

    display: flex;
    flex-flow: column nowrap;
    align-content: flex-end;
    align-items: center;

    position: absolute;
    bottom: 0;
    color: $white;
    background-image: linear-gradient(
      to top,
      rgba(0,0,0,1), rgba(0,0,0,0.0)
    );
}

.h4 {
    text-align: center;
    max-width: 100%;
    margin: 10px 0 0 0;
}

.h3 {
    text-align: center;
    max-width: 100%;
    margin: 10px 0 10px 0;
}
