@import 'definitions';

.productCategoryFilter {
    width: 100%;
    margin-top: 10px;
}

.productCategoryFilter__title {
    @include --filterTitle;
}

.productCategoryFilter__title:hover {
    background-color: transparent;
    cursor: pointer;
}

.productCategoryFilter[data-isdropdownopen='true'] {
    background-color: $lighter-grey2;

    @include device('tablet') {
        background-color: white;
    }
}

.productCategoryFilter[data-isdropdownopen='false'] {
    background-color: transparent;
}

.productCategoryFilter[data-isdropdownopen='true'] > div {
    background-color: $lighter-grey2;

    @include device('tablet') {
        background-color: white;
    }
}

.productCategoryFilter[data-isdropdownopen='false'] > div {
    height: 0px;
    overflow-y: hidden;
}