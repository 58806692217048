@import 'definitions';

.image {
    max-height: 600px;
    aspect-ratio: 2.4 / 1;
}

.noGradient {
    background: none !important;
}

.backToSearch {
    position: absolute;
    align-items: center;
    top: 0;
    left: 0;
    padding: 5px 16px 5px 11px;
    margin-left: 6px;
    margin-top: 16px;
    background: rgba(0, 0, 0, 0.4);
    border-radius: 15.5px;
    color: $white;
    display: flex;
    border: none;
    font-size: 1rem;
    line-height: 1.56;
    &:hover {
        background-color: rgba(0, 0, 0, 0.4);
        color: $white;
    }

    @include device('tablet') {
        padding: 5px;
    }
}

.backToSearchText{
    font-family: SkattaSansUI-Medium;
    margin-left: 5px;
    font-size: 14px;
    letter-spacing: 0.23px;
    text-transform: uppercase;
    @include device('tablet') {
        display: none;
    }
}

.heading {
    color: $black;
}

.container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.headerLeadContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.attributeCategoryContainer {
    display: flex;
    flex-direction: row;

    section {
        flex: 1 1 0;
    }

    @include device('tablet') {
        flex-direction: column;
        align-items: center;
        margin-top: 5px;
    }
}
