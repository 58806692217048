$kcc-font-size: 16px;

.container {
    font-size: $kcc-font-size;
}

.clientOutreachQuestion {
    font-weight: 500;
    padding: 0 0 0.5rem;
    letter-spacing: 0;
    font-size: $kcc-font-size;
}

.clientOutreachTertiary {
    display: flex;
    justify-content: center;

    .buttonLinkContent {
        font-size: 0.75rem;
    }

    svg {
        padding-bottom: 2px;
    }
}
