.dialogBox {
  opacity: 0;
}

.dialogBox[open] {
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  outline: none;
  border: none;

  height: 100%;
  background: none;
}

.dialogBox::backdrop {
  background-color: rgb(0 0 0 / 0%);
}

.dialogBox[open]::backdrop {
  background-color: rgba(0, 32, 91, 0.5);
  animation: backdrop-fade-in 0.2s ease-in-out;
  @media (prefers-reduced-motion) {
    animation: none;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
    display: none;
  }

  100% {
    opacity: 1;
    display: block;
  }
}

@keyframes backdrop-fade-in {
  0% {
    background-color: rgb(0 0 0 / 0%);
  }

  100% {
    background-color: rgba(0, 32, 91, 0.5);
  }
}

.container {
  max-height: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  margin: auto;
  max-width: 1000px;
  padding: 16px;
  border-radius: 16px;
  border: 1px solid rgb(204, 204, 204);
  background-color: white;

  color: #282828;
  .headerText {
    text-align: center;
    margin-top: 0.5rem;
    margin-bottom: 1rem;
    padding: 0 24px;
  }
  .header {
    display: flex;
    align-items: center;
    justify-content: right;
    gap: 1.5rem;
    position: relative;

    .buttonClose {
      position: absolute;
      color: inherit;
      border: 1px solid #ccc;
      padding: 0;
      width: 25px;
      height: 25px;
      font: inherit;
      cursor: pointer;
      outline: inherit;
      top: -1.5rem;
      right: -1.5rem;
      background-color: #fff;
      border-radius: 50%;
      color: #666;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .buttonClose:focus {
      outline: revert;
    }
  }
  .content {
    flex: 1;
    overflow: auto;
    p {
      margin-top: 0;
    }
  }
}