@import 'definitions';


.banner {
    position: fixed;
    bottom: 0;
    left: 0;
    right:0;
    width: 100%;

    box-sizing: border-box;
    display: flex;
    align-items: center;
    text-align: left;
    background-color: $white;
    border: 1px solid #e1e1e1;
    padding: 0 32px;

    & p {
        margin: 10px 0 0 0;
        width: 100%;
    }
}

.bannerContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 100%;
    padding: 32px;
}

.bannerCloseButton {
    position: absolute;
    display: flex;
    flex-direction: column;

    right: 0%;
    top: 0%;
    margin-right: -15px;
    cursor: pointer;
    align-items: center;
    background-color: transparent;
    padding: 32px;
    border: 0;
    &:hover {
        background-color: transparent;
        color: $orange;
    }
    & svg {
        margin-top: 10px;
    }
}

.bannerNoticeIcon {
    position: absolute;
    display: flex;
    top: 40%;
    align-items: center;
    background-color: transparent;
    padding: 0;
    border: 0;
    & svg {
        margin-left: -50px;
    }
}

