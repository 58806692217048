.bubble {
    border: 1px solid #F86800;
    border-radius: 30px;
    color: #F86800;
    vertical-align: middle;
    align-self: flex-start;
    background-color: #ffffff;
    font-size: 0.7rem;
    cursor: pointer;
    svg:nth-of-type(2) {
        margin-left: auto;
        padding-right: 5px;
    }
    span:nth-of-type(1) {
        padding-left: 7px;
    }
}

.bubblePrimaryOnly {
    svg {
        margin-left: auto;
        padding-right: 5px;
    }
}
