@import 'definitions';

.bubbleContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center
}

.categoryBubble {
    @include --bubbleButton($bubble-grey, 1px solid $bubble-grey);
}

.categoryBubble:hover {
    background-color: #f5f5f5;
}

.tagBubble {
    @include --bubbleButton($darker-orange, 1px solid $darker-orange);
}

.tagBubble:hover {
    background-color: #faeade;
}

.categoryBubbleContent {
    padding: 0 8px;
    div {
        display: flex;
        align-items: center;
    }
    align-items: center;
    justify-content: center;
}

.categoryBubbleContent:first-letter {
    text-transform: uppercase;
}
