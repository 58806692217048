
@import 'definitions';

.suggestionContainer {
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 110%;
    left: 0;
    width: 100%;
    z-index: 10;
    font-size: 16px;
    background-color: #ffffff;
    color: $black;
    box-shadow: 0px 10px 10px -5px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.1);

    h3 {
        margin-top: 0;
        font-family: SkattaSansUI-Bold !important;
    }

    b {
        font-family: SkattaSansUI-Bold;
    }
}

.chip {
    font-size: 16px;
    color: $darker-grey;
    display: flex;
    align-items: center;
    border: 1px solid $darker-grey;
    border-radius: 20px;
    padding: 0.1rem 0.75rem;
    white-space: pre-wrap;
    cursor: pointer;

    &:hover, &:focus {
        background-color: $white;
        color: $darker-orange;
        border-color: $darker-orange;
        cursor: pointer;
     }
}

.topPart {
    display: flex;
    align-items: center;
    height: 60px;
    padding-left: 10px;
    background-color: #f6f6f6;
}

.bottomPart {
    display: flex;
    flex-flow: row wrap;
    gap: 15px;
    padding: 15px 15px;
    align-items: center;
    margin: 0;
}

.displayOverlay {
    position: fixed;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.12);
    z-index: 10;
}