@import 'definitions';

.bubbleContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center
}

.tagBubble {
    @include --bubbleButton($darker-orange, 1px solid $darker-orange);
}

.tagBubble:hover {
    background-color: #faeade;
}

.tagBubbleContent {
    div {
        display: flex;
        align-items: center;
    }
    align-items: center;
    justify-content: center;
}