@import 'definitions';
$kcc-font-size: 16px;

.productCardContainer {
    display: flex;
    width: 1800px;
    z-index: 1;

    @include device('desktop') {
        flex-direction: column;
    }

    @media screen and (max-width: 1800px) {
        max-width: 1800px;
    }
}

.productCardMain {
    flex: 66%;

    @include device('desktop-l') {
        flex: 60%;
    }
}

.breadCrumb {
    padding: 1.5rem;

    @include device('desktop') {
        padding: 1rem 1rem 0rem 1rem;
    }
}

.batchesContainer {
    display: flex;
    flex-direction: column;

    &.main {
        @include breakpoint('l') {
            display: none;
        }

        padding: 0 1rem;
    }

    &.pane {
        display: none;

        @include breakpoint('l') {
            display: flex;
        }
    }
}

.batchesTitle {
    font-weight: 400;
}

.productCardPane {
    flex: 34%;
    box-shadow: rgba(0, 0, 0, 0.4) -5px 0px 8px -9px;
    padding: 40px 24px;
    display: none;

    @include device('desktop-l') {
        flex: 40%;
    }

    @include breakpoint('l') {
        display: block;
    }
}

.productDetails {
    display: none;
    padding: 1rem;
    font-size: $kcc-font-size;

    @include device('desktop') {
        display: block;
    }
}

.productDescription {
    h1 {
        font-size: 1.4rem;
        font-weight: 600;
        text-align: left;
        padding: 0;
        letter-spacing: 0;
    }

    &.main {
        display: block;

        h1 {
            margin: 12px;


            @include device('desktop') {
                display: block;
                padding: 1.5rem 0.75rem 0.75rem;
                margin: 0;
                font-size: 1.25em;
            }

            @include breakpoint('l') {
                display: none;
            }
        }
    }

    &.pane {
        .productDetails {
            display: block;
        }

        @include device('desktop') {
            h1 {
                display: none;
            }

            .productDetails {
                display: none;
            }
        }
    }
}

.productCardButtonWrapper {
    margin-top: 12px;
}

.clientOutreachPane {
    padding: 16px 16px 8px;
    margin: 24px 0;
    border-radius: 12px;
    background-color: var(--grey-03);
    border: 1px solid var(--grey-05);
    display: flex;
    flex-direction: column;
    gap: 8px;
    font-size: 15px;

    @include device('desktop') {
        display: none;
    }
}

.clientOutreachQuestion {
    font-weight: 500;
    padding: 0 0 0.5rem;
    letter-spacing: 0;
    font-size: $kcc-font-size;
}

.clientOutreachMain {
    padding: 16px 16px 8px;
    margin: 0 8px 24px;
    border-radius: 12px;
    background-color: var(--grey-04);
    border: 1px solid var(--grey-05);
    flex-direction: column;
    gap: 8px;
    display: none;

    @include device('desktop') {
        display: flex;
    }
}

.clientOutreachTertiary {
    display: flex;
    justify-content: center;

    .buttonLinkContent {
        font-size: 0.75rem;
    }

    svg {
        padding-bottom: 2px;
    }
}

.additionalProdLink {
    width: 100%;
}
