@import 'definitions';

.productBrandCardContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 300px;
    height: 160px;
    border-radius: 4px;
    margin-bottom: 5rem;
    @include breakpoint('s') {
        margin-bottom: 30px;
    }
}

// React-router Link
.productBrandCardContainer > a {
    height: 100%;
    width: 100%;
}

.productBrandCard__heroImage {
    width: 100%;
    height: 100%;
    border-radius: 4px 4px 0 0;
    display: block;
}

.productBrandCard__info {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    border-radius: 0 0 4px 4px;
    color: $grey-80;
}

.productBrandCard__info__logoContainer {
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-items: center;
    align-self: center;
    margin-top: -50px;
    width: 100px;
    height: 100px;
    background-color: $white;
    border-radius: 50%;

    & img {
        margin-bottom: 23%;
        object-fit: contain;
    }
}

.productBrandCard__info__title {
    display: none;
    margin: 0;
    margin-bottom: 1rem;
    font-size: 1.25rem;
    text-transform: none;
}

@media only screen and (max-width: 600px) {
    .productBrandCardContainer {
        position: relative;
        margin-bottom: 0;
        width: 100%;
    }

    .productBrandCardContainer > a {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }

    .productBrandCard__heroImage {
        border-radius: 4px;
        object-fit: cover;
    }

    .productBrandCard__info {
        position: absolute;
        bottom: 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 0;
        margin-left: 0.5rem;
        margin-bottom: 0.5rem;
        background-color: transparent;

    }

    .productBrandCard__info__logoContainer {
        width: 64px;
        height: 64px;
        margin-bottom: 0;
        margin-top: 0;
    }

    .productBrandCard__info__title {
        display: block;
        color: white;
        margin: 0;
        margin-left: 0.5rem;
    }
  }

@include device('mobile') {
    .productBrandCardContainer {
        margin-bottom: 30px;
    }
}
