@import 'definitions';

.section {
    display: flex;
    justify-content: center;
}

.largeHeader {
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
}

.headerContainer {
    margin: auto;
    display: flex;
    flex-flow: row nowrap;
    height: $largeHeaderHeight;
    background-color: $orange;
    padding: 0 15px;
    box-sizing: border-box;
    max-width: $headerFooterContentMaxWidth;
    width: 100%;
}

.headerLogo {
    display: flex;
    flex-flow: row nowrap;
    flex-shrink: 1;
    flex-basis: 160px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
}

.linkContainer {
    flex-grow: 1;
}

.topRow {
    justify-content: flex-end;
}

.bottomRow {
    display: flex;
    justify-content: flex-start;
    flex: 1;
}

.buttonLinkContainer {
    display: flex;
    justify-content: flex-end;
    flex: 1;
    flex-flow: row nowrap;
}

$link-item-margin-bottom: 4px;
@mixin LinkItemSharedProps {
    font-size: 14px;
    line-height: 20px;
    margin: 8px 8px $link-item-margin-bottom;
}

.linkItem {
    font-family: SkattaSansUI-Medium;
    display: flex;
    p {
        margin: 0;
    }
    @include LinkItemSharedProps();
    color: $white;
    outline: 1px solid $white;
    border-radius: 4px;
    align-items: center;
    padding: 3px 10px;

    &:focus {
        @include --navBarLinkFocus;
    }
}

.searchLink {
    font-family: SkattaSansUI-Medium;
    align-self: stretch;
    @include LinkItemSharedProps();
    margin-right: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: $white;
    svg {
        fill: $white;
        margin-right: 10px;
    }
    white-space: pre-line;

    &:focus {
        @include --navBarLinkFocus;
    }
}

.kespronetLinkItem {
    color: $orange;
    background-color: $white;
    width: 115px;
}

.linkText {
    font-family: SkattaSansUI-Medium;
    font-size: 14px;
    color: $orange;
    margin-left: 9px !important;
}

.becomeClientText {
    font-family: SkattaSansUI-Medium;
    width: 75px;
    font-size: 14px;
    white-space: pre-line;
    box-sizing: border-box;
}

.becomeClientLink {
    height: 40px;
    & .sv {
        padding-left: 10px;
    }
    & .en {
        padding-left: 6px;
    }
    & .ru {
        padding-left: 6px;
    }
}

.languageSelect {
    text-transform: uppercase;
    align-self: stretch;

    .dropDownContainer {
        background-color: $orange;
        width: 100%;
    }

    .mainNavItem {
        @include LinkItemSharedProps();
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 0;
        padding: 0 0.5rem $link-item-margin-bottom;
        white-space: pre-line;
    }
}


.linkIcon {
    display: flex;
    color: $orange;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.mainNavItem {
    letter-spacing: 0px;
    font-size: 16px;
    line-height: 22px;
    border-radius: 4px 4px 0 0;
    margin-top: 10px;
    color: $white;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0 17px;
    p {
        font-family: SkattaSansUI-Medium;
        margin: 0;
    }

    &.active {
        background-color: $white;
        color: $orange;
        svg {
            transform: rotate(180deg);
        }
    }

    &:focus {
        @include --navBarLinkFocus;
    }

    svg {
        $iconSize: 26px;
        fill: currentColor;
        height: $iconSize;
        width: $iconSize;
        transition: transform .2s ease-in-out;
    }

    @media only screen and (min-width: 1025px) and (max-width: 1080px) {
        // This is an edge case as the content in header doesn't fit the screen.
        padding: 0 10px;
    }
}

.secondaryLinksContainer {
    display: flex;
    gap: 1rem;
    margin-top: 8px;
}

.secondaryNavItem {
    font-family: SkattaSansUI-Medium;
    font-size: 12px;
    line-height: 17px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: $white;
    margin: 0 8px;

    &:focus {
        @include --navBarLinkFocus;
    }
}

.mainNavItemContainer {
    position: relative;
    display: flex;
    align-items: stretch;
    justify-content: center;
    &:hover {
        .mainNavItem:not(.active), .showDropdown {
            & ~ .dropDownContainer {
                display: flex;
            }
            background-color: $white;
            color: $orange;
            svg {
                transform: rotate(180deg);
            }
        }
    }

    &:focus {
        @include --navBarLinkFocus;
    }
}

.dropDownContainer {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    flex-direction: column;
    z-index: 12;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    width: calc(100% + 55px);
}

.dropDownItem {
    display: flex;
    background-color: $orange;
    padding: 10px;
    white-space: nowrap;
    color: $white;
    font-size: 14px;
    line-height: 24px;
    &:hover {
        background-color: #ce5000;
    }
    &.active {
        background-color: $white;
        color: $orange;
    }
    p {
        margin: 0;
    }
}

.headerSubNav {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    height: 70px;
    background-color: $white;
}

.subNavItem {
    @include --link;
    text-align: justify;
    color: $grey-80;
    display: inline-block;
    position: relative;
    margin: 0 1%;
    &.active {
        color: $orange;

        &:after {
            content: "";
            width: 60%;
            border-top: 2px solid $orange !important;
            display: block;
            margin: 0 auto;
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
        }
    }
}
