@import 'definitions';

$input-padding: 15px;

.searchBar {
    height: 48px;
    width: 100%;
    border-radius: 3px;
    box-sizing: border-box;
    background-color: $white;
    padding-right: 15px;
    filter: drop-shadow(0 2px 8px rgba(0, 0, 0, 15%));

    @include device('mobile') {
        padding: 10px;
    }
    z-index: 11;
    position: relative;
}

.searchInput {
    flex: 99;
    @include --paragraph;
    border: none;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    background-color: $white;
    font-size: 18px;
    padding: 10px;
    color: $grey-80;

    @include device('tablet') {
        padding-left: 5px;
    }
    &::-webkit-search-decoration,
    &::-webkit-search-cancel-button,
    &::-webkit-search-results-button,
    &::-webkit-search-results-decoration {
        display: none;
    }

    &:focus-visible {
        outline: 2px solid $accessibility-kb-focus;
    }

    &::placeholder {
        color: $bubble-grey;
        opacity: 1;
    }
}

.searchIcon {
    all: unset;
    color: #969696;
    display: flex;
    flex: 1;
    margin-left: 0.5rem;

    &:focus {
        outline: 2px solid $accessibility-kb-focus;
    }
    &:hover {
        background-color: unset;
        color: $orange;
        cursor: pointer;
    }
}

.orange {
    color: $orange;
}

.clearButton {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: SkattaSansUI-Medium;
    width: 20px;
    height: 20px;
    overflow: clip;
    border: none;
    color: #969696;
    cursor: pointer;
    padding: 0;
    margin-left: 0.5rem;

}

.clearIcon {
    /* The icon is defined as text so it does not align or scale perfectly. */
    margin-top: 2px;
    transform: scale(2.5);
}

.clearButton:hover {
    background-color: unset;
    color: #969696;
}

.showFiltersButton {
    @include --link;
    text-align: justify;
    border-left: 1px solid $lighter-grey;
    padding-left: 1rem;
}

.showForMobile {
    display: none;
    @include device('tablet') {
        display: initial;

        & svg {
            display: none;
        }
    }
}
.hideForMobile {
    display: initial;
    @include device('tablet') {
        display: none;
    }
}
