@import 'definitions';

.column {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;

	& > *:not(:last-child) {
		margin-bottom: 1rem;
	}
}

.reverseDirectionInMobile {
    @include device('mobile') {
        flex-direction: column-reverse;
    }
}
