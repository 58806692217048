@import 'definitions';

.image{
    max-height: 552px;
    aspect-ratio: 2.6 / 1;
}

.noGradient {
    background: none !important;
}

.header__backButton {
    display: none;
}

.header__info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    & > * {
        color: $grey-80 !important;
    }

    h1 {
        margin: 0;
    }

    @include device('mobile') {
        margin: 0 !important;
    }
}

@media only screen and (max-width: 769px) {
    .header__backButton {
        $currentColor: white;
        align-self: flex-start;
        border: none;
        background-color: rgba(0,0,0,0.4);
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 20px;

        & > a {
            display: flex;
        }

        &:hover {
            background-color: rgba(0,0,0,0.6);
        }
    }

    .header {
        padding: 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        position: absolute;
    }
}
