@import 'definitions';

.centered {
    padding: 0 18%;
    display: flex;
    flex-direction: column;
    align-items: center;

    h2, h3, h4, h5, h6, > p {
        text-align: center;
        margin: 20px;
    }

    p {
        margin: 0 0 10px;
    }

    @include device('tablet') {
        padding: 0 5%;
    }
}

.header__info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    & > * {
        color: $grey-80 !important;
    }

    h1 {
        margin: 0;
    }

    @include device('mobile') {
        margin: 0 !important;
    }
}

.todayOpened {
    margin: 0.75rem 0 1.5rem 0;
}

.contactInfoPage {
    display: flex;
    flex-direction: column;
    word-break: break-word;
    text-align: center;
}

.contactInfoPage__contentContainer {
    margin: 0 auto;
    max-width: 680px;
}

.becomeACustomerLinkContainer {
    align-self: flex-end;
    margin: 2rem 2rem 2rem 0;

    @include device('tablet') {
        align-self: center;
        margin: 1rem 0 2rem 0;
    }
}

.becomeClientContainer {
    display: flex;
    justify-content: center;
}

.contactLink {
    padding: 0.375rem 2rem;
    border-radius: 4px;
}

.contactLinkContainer {
    margin: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media only screen and (max-width: 768px) {
    .contactInfoPage__contentContainer {
        padding: 0 1rem;
    }
}

.headerBarContainer {
    margin: 0 1rem 3rem 1rem;
    padding: 0.5rem 0 1rem 0;
}

.headerBar {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    text-align: left;

    & > span {
        margin-left: 10px;
        margin-right: 30px;
    }

    @include breakpoint('m') {
        flex-direction: row;
    }

    @include device('tablet') {
        flex-direction: column;
    }
}

.phoneCallInfo {
    text-align: center;
    font-size: 0.825rem;
    margin: 0;
    margin-top: 16px;
}

.iconAndLabel__icon {
    background-color: white;
    border-radius: 20px;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 1px 2px 2px rgba(0,0,0,0.1);
    color: $orange;

    span {
        margin: auto;
    }
}

a.iconAndLabel:hover > span {
    text-decoration: underline;
}

.iconAndLabel__icon > span {
    color: $orange;
    font-size: 0.875rem;
    letter-spacing: -1.15px;
}

.iconAndLabel {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 10px;
    color: $grey-80;

    & > span {
        margin-left: 15px;
    }

    @include device('tablet') {
        width: 80%;
    }
}

@media only screen and (max-width: 769px) {
    .headerBarContainer {
        margin: 0 1rem 1rem 1rem;
        padding: 0.5rem 0 1rem 0;
        border-bottom: 1px dashed rgba(0,0,0,0.4);
    }
}

.contactButton {
    font-size: 18px;
    text-transform: none;
}

.emailButton {
    margin-left: 5px;
    padding: 0 10px;
    color: $darker-orange;
    border: 0px;
    border-radius: calc(0.47 * 290px);
    font-size: 14px;
    word-break: break-word;
    text-align: left;
}

.emailButton:hover {
   color: white;
   background-color: $orange;
}
