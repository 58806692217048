@import "namifesti";

.container {
    display: grid;
    place-items: center;
    position: relative;
    padding-bottom: $spacing-8x;

    h2 {
        margin-top: -3rem;
        padding-bottom: $spacing-2x;
        text-align: center;

        svg {
            height: 318px;
            width: 90vw;
            max-width: 250px;
        }
    }

    .content {
        display: grid;
        gap: $spacing-2x;
        padding: 0 $spacing-2x;
        max-width: $desktop-max-content-width;
        grid-template-columns: 1fr 1fr;

        @media only screen and (min-width: $breakpoint-tablet) {
            row-gap: 3rem;
        }

        @media only screen and (min-width: $breakpoint-desktop) {
            gap: $spacing-4x;
        }

        div {
            h3 {
                margin: 0;
                color: $intohimo-orange;
                display: flex;
                flex-direction: column;
                font-size: 1rem;
                text-transform: uppercase;
                font-family: SkattaSansUI-Bold, sans-serif;
                padding-bottom: 0.5rem;

                svg {
                    height: 130px;
                    width: 100%;
                }
            }

            p {
                margin-top: -2rem;
                padding: 0;
                word-wrap: break-word;
                hyphens: auto;

                @media only screen and (min-width: 425px) {
                    margin-top: -1rem;
                }

                @media only screen and (min-width: 550px) {
                    margin-top: -0.5rem;
                }

                @media only screen and (min-width: 768px) {
                    margin-top: 0.5rem;
                }
            }
        }
    }

    .source {
        font-style: italic;
        opacity: 0.8;
    }
}
