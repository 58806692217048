@import 'definitions';

.lead {
    @include --lead;
    width: 67%;
    margin-top: 40px;
    margin-bottom: 30px;

    @include device('tablet') {
        align-self: center;
        margin-top: 30px;
        margin-right: 23px;
        margin-left: 23px;
        width: $contentWidthTablet;
        max-width: 520px;
    }

    @include device('tablet') {
        color: $grey-80;
    }
}
