@import "namifesti";

$margin-from-edges: 2rem;

.container {
    display: grid;
    place-items: center;
    color: $intohimo-blue;
    padding: $spacing-4x 0;

    h2 {
        text-align: center;
        text-transform: uppercase;
        font-size: 2rem;
        margin: 0 $margin-from-edges $spacing-2x;

        @media only screen and (min-width: $breakpoint-desktop) {
            max-width: $desktop-max-content-width;
        }
    }

    > p {
        margin: 0;
        text-align: center;
        padding: 0 $spacing-2x $spacing-2x;
        @media only screen and (min-width: $breakpoint-desktop) {
            max-width: $desktop-max-content-width;
        }
    }

    .content {
        display: grid;
        place-items: center;
        margin: 0 $margin-from-edges $spacing-2x;
        overflow: clip;
        max-height: 35vh;
        position: relative;

        &:after {
            // Adds the foggy overlay to the bottom of the content
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            height: 100px;
            background: linear-gradient(to bottom, rgba(255, 255, 255, 0), $intohimo-white);
        }

        &.expanded {
            max-height: unset;

            &:after {
                // Removes the foggy overlay in the bottom.
                content: unset;
            }
        }

        div {
            padding-bottom: $spacing-2x;

            h3 {
                margin: 0 0 $spacing-1x;
                padding: 0;
                text-align: center;
            }

            p {
                margin: 0;
                padding: 0;
                text-align: center;
            }
        }

        @media only screen and (min-width: $breakpoint-desktop) {
            max-width: $desktop-max-content-width;
        }
    }
}
