@import 'definitions';

.container {
    z-index: 99999;
    position: relative;
    background: $orange;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px!important;
}

.mobileContainer {
    z-index: 99999;
    position: relative;
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-start;
    justify-content: flex-end;
    background: $orange;
    left: -4.5px;
    & :hover {
        cursor: pointer;
    }
}

.containerText {
    padding-left: 4px;
    transform: translateY(15%);
}

.hidden {
    display: none;
}
