@import 'definitions';

.searchContainer {
    display: flex;
    justify-content: center;
}

.searchBar {
    width: 100%;
    max-width: 520px;
    position: relative;
    z-index: 11;

    @include device('tablet') {
        &::before,
        &::after {
            content: none !important;
        }
    }
}

.searchBar:before,
.searchBar:after {
    content: '';
    position: absolute;
    z-index: -1;
    bottom: 16px;
    left: 20px;
    width: 50%;
    height: 20%;
    box-shadow: 0 14px 14px rgba(0, 0, 0, 0.5);
    transform: rotate(-1deg);
}

.searchBar:after {
    right: 20px;
    left: auto;
    transform: rotate(1deg);
}

.searchInput {
    flex: 1;
    @include --paragraph;
    padding: 15px 15px 15px 15px;
    border: 0px solid;
    z-index: 11;
    position: relative;
    background-color: $white;
    @include device('tablet') {
        padding: 15px 15px 15px 5px;
        overflow: hidden;
    }
    &::-webkit-search-decoration,
    &::-webkit-search-results-button,
    &::-webkit-search-cancel-button,
    &::-webkit-search-results-decoration {
        display: none;
    }
}

.clearButton {
    font-family: SkattaSansUI-Medium;
    position: relative;
    left: -20px;
    cursor: pointer;
    width: 0;
    font-size: 20px;
    color: #969696;
    z-index: 12;
}
