@import 'definitions';
$border-radius: 8px;

.cardWrapper {
    position: relative;
    width: 100%;
    height: 100%;
    display: inline-block;
}

.eventCard {
    height: 100%;
    border-radius: $border-radius;
    box-shadow: 0 1px 14px -1px rgb(0 0 0 / 14%);
    color: $grey-80;
    &:hover {
        box-shadow: 0 0 8px 4px rgba(0,0,0,0.3);
    }
}

.eventCardLink {
    z-index: 1;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    &:hover {
        box-shadow: 0 0 8px 4px rgba(0,0,0,0.3);
    }
}

.linkInBody {
    z-index: 2;
}

.imageContainer {
    overflow: hidden;
    display: flex;
    border-radius: 8px 8px 0 0;
}

.eventImage {
    max-width:100%;
    height: auto;
}

.infoContainer {
    font-family: SkattaSansUI-Medium;
    position: relative;
    top: -30px;
    background: $white;
    width: 80%;
    align-self: center;
    border-radius: 8px;

    & > hr {
        width: 60px;
        height: 1px;
        border: none;
        background: $black;
        margin-top: 4px;
        margin-bottom: 0px;
    }
}

.infoText {
    font-family: SkattaSansUI-Medium;
    margin: 6px 4px 0px 4px;
    text-align: center;
    font-size: 14px;
}

.centerText {
    text-align: center;
}

.cardBody {
    position: relative;
    top: -20px;
    align-self: center;
    text-align: center;
}

.cardBodyHeading {
    margin: 12px 0;
    text-align: center;
    word-break: break-word;
    padding: 0 8px;
}

.cardBodyText {
    margin: 0px;
    margin-bottom: 5px;
    align-items: center;
    text-align: center;
    padding: 8px 8px 0 8px;
    display: flex;
    justify-content: center;
    word-break: break-word;
    font-size: 18px;
}

.eventLocationText {
    align-items: center;
    text-align: center;
    padding: 8px;
    display: flex;
    justify-content: center;
    font-size: 14px;
    gap: 6px;
    color: $darker-grey;
    & > a {
        color: $darker-grey;
    }
}

.linkText {
    color: $orange;
    margin-top: 8px;
    font-size: 16px;
}

.hideOnTablet {
    @include device('tablet') {
        display: none;
    }
}
