@import 'definitions';

.container {
    margin: 2rem 0 2rem 0;

    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: flex-start;
    max-width: 100%;
}
