@import 'definitions';

.feedbackly {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: $contentWidth;
}

:global ._fbly-main {
    height: 100%;
    flex: 1;
    min-height: 0px !important;
    background-color: transparent;
}

.feedbackly iframe {
    min-height: 270px !important;
    height: 100%;
    width: 100%;
}

@media only screen and (min-width: 1057px) {
    .feedbackly iframe {
       min-height: 420px !important;
    }
}

@include device('tablet') {
    .feedbackly {
        width: $contentWidthTablet;
    }

   .feedbackly iframe {
        min-height: 280px !important;
    }
}

@include device('mobile') {
    .feedbackly {
        width: 100%;
    }
}


@media only screen and (min-width: 628px) and (max-width: 768px) {
    .feedbackly iframe {
        min-height: 410px !important;
     }
}

@media only screen and (max-width: 549px) {
    .feedbackly iframe {
       min-height: 270px !important;
    }
}