@import 'definitions';

@mixin placeholderBackgroundAnimation() {
    background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
    border-radius: 5px;
    background-size: 200% 100%;
    -webkit-animation: placeholderGradient 2s infinite ease-out;
    animation: placeholderGradient 2s infinite ease-out;

    @keyframes placeholderGradient {
        0% {
            background-position: 0% 50%;
        }

        50% {
            background-position: 75% 50%;
        }

        100% {
            background-position: 0% 50%;
        }
    }
}

.card {
    background-color: $white;
    transition: all .1s ease-in;
    border-radius: 3px;
    box-shadow: 0 0 8px 4px #dddddd;
    max-width: 300px;

    &:hover {
        box-shadow: 0 3px 10px 0 rgba(0, 0, 0, .50);
    }
}

.loading {
    @include placeholderBackgroundAnimation;
    width: 50%;
}

.oneCard,
.twoCards,
.threeCards {
    @extend .card;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
}

.contentContainer {
    align-items: center;
}

.title {
    text-align: center;
    padding: 10px 20px 20px 20px;
    line-height: 20px;
    margin: 0;
    overflow-wrap: break-word;
    word-break: break-word;
    color: $grey-80;

    @include device('tablet') {
        padding: 0 20px 10px 20px;
    }
}

.logo {
    margin: 1em auto;
    fill: $orange !important;
    width: 42px;
    height: 42px;
    min-width: 42px;
    min-height: 42px;

    @include device('mobile') {
        width: 28px;
        height: 28px;
        min-width: 28px;
        min-height: 2px;
    }
}
