@import 'definitions';

.linksbox {
    display: flex;

    @include breakpoint('m') {
        border-right: dashed 1px rgba(255, 255, 255, 0.30);
        padding-right: 1rem;
    }

    @include breakpoint('l') {
        border-right: dashed 1px rgba(255, 255, 255, 0.30);
        padding-right: 5%;
    }

    & .list {
        padding: 0 1rem;
    }
}

.links {
    display: flex;
}

.link {
    @include --s-paragraph;
    text-transform: none;
    color: $white;
}

.newsletterSignUpButton {
    margin: 0 !important;
}

.newsLetterSignUpButtonContent {
    font-size: 15px;
}
