@import 'definitions';

.landingPageContent {
	display: flex;
	flex-direction: column;
	align-items: center;
	overflow-x: hidden;
	overflow-y: hidden;
	$sideMarginDesktop: 6rem;
	$sideMarginTablet: 2rem;
	$sideMarginMobile: 1.4rem;
	$verticalMarginDesktop: 2rem;
	$verticalMargin: 1rem;
	& > * {
		&:not(:last-child) {
			margin-bottom: $verticalMarginDesktop;
			@include device('desktop') {
				margin-bottom: $verticalMargin;
			}
		}
		margin-left: $sideMarginDesktop;
		margin-right: $sideMarginDesktop;
		max-width: calc(100% - 2*$sideMarginDesktop);
		@include device('desktop') {
			margin-left: $sideMarginTablet;
			margin-right: $sideMarginTablet;
			max-width: calc(100% - 2*$sideMarginTablet);
		}
		@include device('mobile') {
			margin-left: $sideMarginMobile;
			margin-right: $sideMarginMobile;
			max-width: calc(100% - 2*$sideMarginMobile);
		}
	}
}
