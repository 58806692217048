@import 'definitions';

.image {
    max-height: 720px;
    aspect-ratio: 2 / 1;
}

.noGradient {
    background: none !important;
}

.articleFooter {
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    width: $contentWidth;
    margin-top: 2rem;

    @include device('tablet') {
        width: $contentWidthTablet;
    }
}

.recommendations > h3 {
	text-align: left;
	margin-left: 1rem;
}

.headerLeadContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.heading {
    color: $black !important;
}

.tagContainer {
    margin: 8px 0px !important;
}
