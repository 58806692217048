@import 'definitions';

.maintenanceInfoContainer{
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    p {
        margin:0;
        width: 95%;
    }
    h2 {
        font-size: $h1-font-size-m;
        margin-top: 0;
        margin-bottom: 15px;
        width: 100%;
    }
}

.maintenanceInfo {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 20px;
    width: 54%;
    border: 1px solid $brown;
    background-color: $light-yellow;
    color: $brown; 
    border-radius: 4px;

    @include device('tablet') {
        width: 70%;
    }
}

.markdownPresenterText{
    color: $brown;
}