@import 'definitions';

.border {
    border-top: 1px solid black;
    margin: 0 6rem;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;

     @include device('tablet') {
        border: 0;
        margin: 0;
    }
}

.filters {
    margin-top: 40px;
}
