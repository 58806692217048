@import 'definitions';

$searchInputMaxWidth: 520px;

.combinedSearch {
    background-color: $white;
}

.heroContainer {
    position: relative;
    padding: 3rem 0;
    width: 100%;
    margin-bottom: 2rem;

    @include device('tablet') {
        padding-top: 0;
        margin-top: 0;
    }
}

.header {
    font-size: $h1-font-size-m;
    margin-top: 10px;
    text-transform: none;
}

.searchContainer {
    width: 95%;
    max-width: $searchInputMaxWidth;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    margin-bottom: -30px;
    z-index: 10;
}

.searchTypeSelectContainer {
    margin-bottom: 1.5rem;
}

.searchTypeSelect {
    width: 95%;
    max-width: calc($searchInputMaxWidth + 1rem);
}

.contactsContainer {
    margin: 0 auto;
    width: calc(100% - 2rem);
    h2:first-child {
        margin-top: 0;
    }
}

.loading {
    @include --loadingIndicatorWithScroll;
}

.resetButton {
    all: unset;
    color: #E7702D;
    cursor: pointer;
    text-decoration: underline;

    &:hover {
        background: unset;
        color: #E7702D;
    }

    &:focus-visible {
        outline: 2px solid $accessibility-kb-focus;
    }
}
