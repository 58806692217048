@import 'definitions';

.container {
    display: flex;
    align-items: center;
    max-width: 100%;
}

.icon {
    all: unset;
    color: $orange;
    padding: 0.4rem;
    display: grid;
    place-items: center;


    &:active, &:hover {
        background-color: inherit;
        color: $orange;
        cursor: pointer;
    }

    &.disabled {
        opacity: 0.5;
    }
}

.links {
    display: flex;
    list-style-type: none;
    padding: 0;

    overflow-x: scroll;
    scroll-behavior: smooth;
    // See https://stackoverflow.com/a/38994837
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
        display: none;  /* Safari and Chrome */
    }

    * {
        padding: 0 0.5rem;
    }
}

.link {
    @include --link;
    text-transform: uppercase;
    white-space: nowrap;
}
