@import 'definitions';
@import 'breakpoints';

$content-width: calc(100% - 2rem);

.chipsFilterContainer {
    width: 100%;
    margin-top: 50px;
}

.filterContainer {
    width: 100%;
    margin-bottom: 2rem;

    @include device('mobile') {
        label:first-child{
            margin-left: 15px;
        }
        width: 100%;
        display: flex;
        gap: 0.5rem;
        overflow-x: auto;
        justify-content: flex-start;
        flex-wrap: nowrap;
        margin-bottom: 1rem;
        padding-bottom: 0.75rem;
    }
}

.header {
    align-self: stretch;
}

.header h1 {
    margin-top: 30px;
}

.searchContainer {
    max-width: 520px;
    width: $content-width;
    margin-top: 60px;
}

.customerServiceBoxes {
    display: flex;
    justify-content: center;
    gap: 16px;
    width: 100%;
    margin-top: 20px;
    cursor: pointer;

    @include device('tablet') {
        flex-wrap: wrap;
    }

    h3 {
        font-size: 20px;
        margin: 0;
    }

    .circleSpace {
        justify-content: center;
        align-items: center;
        text-align: center;
        display: flex;
        height: 48px;
        position: relative;
        z-index: 3;
        background-color: transparent;
        bottom: -24px;
        margin-top: -24px;
      }

    .iconCircle {
        justify-content: center;
        align-items: center;
        height: 48px;
        width: 48px;
        background-color: $orange;
        border-radius: 100%;
        display: flex;
        text-align: center;
    }

      .boxContainer {
        width: 22%;
        display: flex;
        flex-direction: column;

        @include device('tablet') {
            width: 45%;
        }
    }

    .boxContent {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 12px;
        height: 50px;
        border: 2px solid $orange;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
        padding: 25px 10px;
        text-align: center;
        top: -2px;
        position: relative;
        z-index: 1;

        /*
         * This max-width must be set explicitly to 1117px because there is
         * a specific range after desktop breakpoint when the viewport continues
         * making the email to go to two lines. After that it'll stay in one line
        */
        @media (max-width: 1117px) {
            justify-content: normal;
            padding-top: 30px;
        }
      }

    .boxTitle {
        color: $grey-80;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        word-break: break-word;

        @include device('tablet') {
            font-size: 12px;
        }

        @include device('mobile') {
            font-size: 12px;
        }
    }

    .boxInfo {
        font-family: SkattaSansUI-Bold;
        color: #282828;
        font-size: 15px;
        line-height: 20px;
        text-align: center;
        word-break: break-word;

        @include device('tablet') {
            font-size: 16px !important;
        }

        @include device('mobile') {
            font-size: 14px !important;
        }
    }

}

.contactDescription {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 70%;
    text-align: center;
    font-size: 16px;
    line-height: 24px;

    p {
        font-family: SkattaSansUI-Medium !important;
    }

    @include device('tablet') {
        width: 85%;
    }

    @include device('mobile') {
        width: 85%;
    }
}
.customerServiceText{
    width: 76%;

    @include device('tablet') {
        width: 100%;
    }
}
.description {
    max-width: 800px;
    width: 80%;
    padding: 20px;
    margin-top: 20px;
    font-size: 80%;
    background-color: $white-two;
    text-align: center;
    white-space: pre-line;
    margin-bottom: 1rem;
}

.contactListContainer {
    width: $content-width;
}

.hideOnLarge {
    @include breakpoint('header') {
        display: none;
    }
}

.mobileNavContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 10px !important;
}

.notFoundText {
    text-align: center;
}
