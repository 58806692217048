@import 'definitions';

@mixin placeholderBackgroundAnimation() {
    background: linear-gradient(-30deg, $light-grey, $lighter-grey);
    background-size: 400% 400%;
    animation: placeholderGradient 3s ease infinite;
    border-radius: $cardBorderRadius;

    @keyframes placeholderGradient {
        0% {
            background-position: 0% 50%;
        }
        50% {
            background-position: 75% 50%;
        }
        100% {
            background-position: 0% 50%;
        }
    }
}

$cardBorder: 1px solid #e1e1e1;
$cardBorderRadius: 3px;

.link {
    max-width:100%;
    box-sizing: border-box;
}

.link.linkSkeleton {
    margin: 0;
    padding: 0;
}

.articleCard,
.articleCardSkeleton {
    margin: 1rem 1rem;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    background-color: $white;
    border: $cardBorder;
    box-shadow: 1px 2px 8px 2px #dddddd;
    transition: all .1s ease-in;
    border-radius: $cardBorderRadius;

    @include device('tablet') {
        margin: 0.5rem 0.5rem;
    }

    picture {
        min-height: 0;
        display: flex;
        flex-grow: 1;
        img {
            min-height: 0;
        }
    }
}

@mixin CardIngressGradient($color) {
    background: linear-gradient(to bottom, transparent 0, transparent calc(100% - 45px),  $color 95%);
}

.articleCard {
    overflow: hidden;
    &:hover {
        background-color: $white;
        box-shadow: 0 3px 10px 0 rgba(0, 0, 0, .50);
    }
}

.long {
    height: 525px;
    border: $cardBorder;

    @include device('tablet') {
        height: 475px;
    }

    @include device('mobile') {
        height: auto;
    }
}

@mixin cardShort {
    height: 245px;

    @include device('tablet') {
        height: 230px;
    }
}

.short {
    @include cardShort();
}

.cardHeaderLong {
    width: 100%;
    height: auto;
    max-height: 245px;
    object-fit: cover;
    object-position: 50% 50%;
    border-radius: $cardBorderRadius;
}

.picture {
    max-height: 245px;
}

.cardImageShort {
    width: 100%;
    object-fit: cover;
    object-position: 50% 50%;
    border-radius: $cardBorderRadius;
    max-width: 100%;
}

.hybridCardInfoPosition {
    position: absolute;
    top: 65%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.shortCardInfoPosition {
    position: absolute;
    top: 68%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
}

.cardInfo {
    height: 150px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}
.cardHeaderHybrid {
    position: relative;
    background: black;
    color: $white;
    height: 245px;
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-end;
    align-items: stretch;
    border-radius: $cardBorderRadius;

    @include device('tablet') {
        height: 230px;
    }
}

.hybridImage {
    opacity: 0.65;
    object-fit: cover;
    object-position: 50% 50%;
    width: 100%;
    height: auto;
}

.cardHeaderShort {
    position: relative;
    text-align: center;
    color: $white;
    border-radius: $cardBorderRadius;
    overflow: hidden;
}

.cardHeaderShort:after {
    content: "";
    background: rgba(0, 0, 0, 0.35);
    position: absolute;
    display: block;
    width: 100%;
    top: 0;
    bottom: 0;
}

.cardBody {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $grey-80;
}

.cardTitle,
.longCardTitle,
.cardTitlePlaceholder {
    margin-top: 0;
    margin-bottom: .5rem;
    padding: 0 0.5rem;
    text-align: center;
}

.cardTitle.cardTitlePlaceholder,
.longCardTitle.cardTitlePlaceholder {
    margin-bottom: 0;
    & > div {
        height: 36px;
        width: 180px;
        margin: 0 auto 0.5rem auto;
        @include placeholderBackgroundAnimation;
    }
}

.cardType,
.longCardType,
.cardTypePlaceholder {
    margin: 0;
    margin-top: .5rem;
    text-align: center;

    @include breakpoint('l') {
        margin-top: 12px;
    }
}

.cardType.cardTypePlaceholder,
.longCardType.cardTypePlaceholder {
    & > div {
        height: 26px;
        width: 100px;
        margin: 0 auto 0.5rem auto;
        @include placeholderBackgroundAnimation;
    }
}

.cardPublishTime,
.longCardPublishTime,
.cardPublishTimePlaceholder {
    text-align: center;
    margin: 0;
    margin-bottom: .5rem;
}



.cardPublishTime.cardPublishTimePlaceholder,
.longCardPublishTime.cardPublishTimePlaceholder {
    margin-bottom: 0;
    & > div {
        height: 26px;
        width: 65px;
        margin: 0 auto 0.5rem auto;
        @include placeholderBackgroundAnimation;
    }
}

.cardTitle,
.cardType,
.cardPublishTime {
    text-shadow: 1px 1px 2px rgba(0,0,0,0.8);
}

.cardLead {
    @include --s-paragraph;
    margin: 0 20px;
    text-align: center;

    @include device('mobile') {
        margin-bottom: 20px;
    }

    &.staticHeightAndFade {
        margin-top: 10px;
        height: 150px;
        overflow-y: hidden;
        position: relative;
    }
}

.cardLead.cardLeadPlaceholder {
    > div {
        height: 26px;
        width: 210px;
        margin: 0 auto 0.5rem auto;
        @include placeholderBackgroundAnimation;
    }
}

.hybridButton {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
    border-radius: 12px !important;

    button:hover {
        text-decoration: none;
    }
}

.hideOnSmall {
    @include device('mobile') {
        display: none;
    }
}
