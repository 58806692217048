@import 'definitions';

.contentPageResultContentArea {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin: 0px 18px;
    justify-content: space-around;
}

.container {
    @include --searchResultContainer;
}

.title {
    margin-top: 0;
    margin-bottom: 1rem;
}

.helpTextContainer {
    background-color:  #fafafa;
    margin-top: 2rem;
    @include device('mobile') {
        margin-bottom: 1rem;
    }
    padding: 2rem 0;
    p {
        margin: 0.5rem;
        span {
            color: #E7702D;
            cursor: pointer;
            text-decoration: underline;
        }
    }
}

