@import 'definitions';

.desktopHighlightedBrandsList {
    width: 100%;
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    // For IE
    justify-content: space-around;
    // For Mozilla and Chrome
    -webkit-justify-content: space-evenly !important;
    gap: 1rem;
    @include device('mobile') {
        display: none;
    }
}

.highlightedBrandsList__item {
    flex-grow: 1;
    max-width: 300px;
}

.mobileCarousel {
    display: block;
    @include breakpoint('s') {
        display: none;
    }
}

.paginationBullet {
    background-color: $orange;
}

@media only screen and (max-width: 640px) {
    .highlightedBrandsList {
        flex-direction: column;
        align-items: center;
        margin-bottom: 2rem;
        gap: 0;
    }
}

@media only screen and (max-width: 600px) {
    .highlightedBrandsList__item {
        max-width: unset;
        width: 100%;
        margin-bottom: 1rem;
    }
}
