@import 'definitions';

.cardList {
    display: grid;
    gap: 1rem;
    margin: 1rem 0;
    width: 100%;

    @include breakpoint('sm') {
        grid-template-columns: repeat(2, 1fr);
    }
}

.maxItemsInRow2 {
    @include breakpoint('l') {
        grid-template-columns: repeat(2, 1fr);
    }
}

.maxItemsInRow4 {
    @include breakpoint('l') {
        grid-template-columns: repeat(3, 1fr);
    }

    @include breakpoint('xxl') {
        grid-template-columns: repeat(4, 1fr);
    }
}
