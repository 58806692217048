@import 'definitions';

$swiperButtonSizeDesktop: 70px;
$swiperButtonSize: 45px;
// button svgs have lots of empty space. We'll compensate for that using this
$swiperButtonOffsetDesktopHero: 55px;
$swiperButtonOffsetDesktop: 35px;
$swiperButtonOffset: 15px;

.relative {
    position: relative;
    width: 100%;
    overflow: visible;
}

.relativeHero {
    @extend .relative;
    margin-left: 0px!important;
    margin-right: 0px!important;
    max-width: 100%!important;
}

.articleCard {
    width: 350px;
    max-width: 350px;
    margin-bottom: 2rem;
    @include device('mobile') {
        max-width: 200px;
    }
}

.heroCard {
    width: 100%;
    margin-bottom: 2em;
}

.swiperButton {
    // the swiper buttons are too close to the cards in this specific range so they are hidden there:
     @media only screen and (min-width: 426px) and (max-width: 530px) {
        display: none;
    }
    z-index: 5;
    position: absolute;
    top: 50%;
    border: 0;
    padding: 0;
    background: transparent;
    cursor: pointer;
    & > svg {
        fill: $orange;
        width: $swiperButtonSizeDesktop;
        height: $swiperButtonSizeDesktop;
        @include device('mobile') {
            width: $swiperButtonSize;
            height: $swiperButtonSize;
        }
    }
    &.next {
        right: -$swiperButtonOffsetDesktop;
        @include device('tablet') {
            right: 0px;
        }
    }
    &.nextHero {
        right: -$swiperButtonOffsetDesktopHero;
        @include device('tablet') {
            display: none;
        }
    }

    &.prev {
        left: -$swiperButtonOffsetDesktop;
        @include device('tablet') {
            left: 0px;
        }
    }
    &.prevHero {
        left: -$swiperButtonOffsetDesktopHero;
        @include device('tablet') {
            display: none;
        }
    }
}

.swiperButton:active, .swiperButton:hover {
    background: transparent;
}

:global {
    .swiper-container {
        // svg buttons have lots of whitespace
        max-width: 100%;
        width: calc(100% - $swiperButtonSizeDesktop + 2*$swiperButtonOffsetDesktop);
        @include device('mobile') {
            width: calc(100% - $swiperButtonSize + 2*$swiperButtonOffset);
        }
    }
}

.paginationBullet {
    background-color: $orange;
}

.title {
    margin-bottom: 1rem;
}

h2 {
    margin-top: 25px;

    @include device('tablet') {
        margin-top: 0px;
    }
}

.scrollMargin {
    margin: 0!important;
    display: block;
    position: relative;
    visibility: hidden;
    top: 15px;

    @include device('tablet') {
        top: 30px;
    }
}
