@import 'definitions';

.tukkukorttiWrapper {
    display: flex;
    @include device('mobile') {
        margin: 0 !important;
        padding: 0;
    }
    @include device('tablet') {
        flex-direction: column;
    }
    & h1 {
        @include breakpoint('m') {
            text-align: left;
            padding: 0 3%;
        }
    }
}

.cardstackWrapper {
    padding: 3%;
    @include device('mobile') {
        padding: 0;
    }
}
