@import 'definitions';

.cardWrapper {
    max-width:100%;
    box-sizing: border-box;
    margin: 1rem 1rem;
    /* calc() doesn't work with flex basis on IE10 */
    flex: 0 0 auto;
    /* 100 % / n, where n = number of cards in product list row */
    width: calc(100% / 4 - 36px);
    @include device('desktop') {
        width: calc(100% / 3 - 36px);
    }

    @include device('tablet') {
        width: calc(100% / 2 - 18px);
        margin: 0.5rem 0.5rem;
    }

    @include device('mobile') {
        width: calc(100% / 1 - 18px);
    }

    &.oneCard {
        min-width: calc(100% / 1 - 36px);
    }

    &.twoCards {
        min-width: calc(100% / 2 - 36px);
    }

    &.threeCards {
        min-width: calc(100% / 3 - 36px);
    }
}

.cardsEqualWidth {
    width: 300px;
}

.productCard {
    min-width: 218px;
    background-color: $white;
    transition: all .1s ease-in;
    border-radius: 3px;

    &:hover {
        box-shadow: 0 3px 10px 0 rgba(0, 0, 0, .50);
    }
}

.cardHeader {
    width: 100%;
    height: 100%;
    border-radius: 3px;
    mix-blend-mode: overlay;
    object-fit: cover;
}

.imageContainer {
    background: rgba($black, 0.04);
    opacity: 0.98;
    width: 100%;
    height: 140px;

}

.iconContainer {
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-items: center;
    align-self: center;
    margin-top: -50px;
    width: 100px;
    height: 100px;
    color: $grey-80;
    background-color: $white;
    border-radius: 50%;
    z-index: 10;

    & img {
        margin-bottom: 23%;
        object-fit: contain;
    }
}

.cardBody {
    margin-top: -1rem;
    color: $grey-80;
    z-index: 15;
}

.cardTitle {
    font-size: 14px;
    line-height: 1.43;
    margin-top: 5px;
    margin-bottom: 2.5rem;
    padding: 0 1.2rem;
    text-align: center;
    max-width: 100%;

    @include device('desktop') {
        font-size: 12px;
    }
}

.cardType {
    font-size: 8px;
    margin: 0;
    margin-top: .5rem;
    text-align: center;
    max-width: 100%;
}
