@import 'definitions';

.productSearchResultsContainer {
    display: flex;
    flex-direction: row;
    max-width: 1280px;
    justify-content: center;
    margin: 0 auto;
    width: 100%;
}

.productSearchResults__filters > * {
    margin-bottom: 1rem;
}
.productSearchDescriptionAndResults {
    width: 100%;
}
.productSearchResults {
    width: 100%;
}

.productSearchResults__filters {
    display: block;

    @include device('tablet') {
        display: none;
        position: fixed;
        top: 60px;
        right: 0;
        bottom: 0;
        overflow: scroll;
        width: 96%;
        height: 55%;
        z-index: 101;
        background-color: white;
    }
}

.mobileFilterView {
    width: 90%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin: auto;
    margin-bottom: 30px;

    @media only screen and (min-width: 769px) {
        display: none;
    }
}

.filterBottomPart {
    @include device('desktop') {
        display: none;
    }
    @include device('tablet') {
        display: block;
    }

    height: 15%;
    display: flex;
    justify-content: center;
    margin: 0;
}

.productSearchResults__filters__useFiltersButton {
    display: none;
}

.productSearchResults__filterContainer {
    max-width: 300px;

    @include device('tablet') {
        max-width: unset;
    }

    .productSearchResults__filters__header {
        display: none;
    }

    .productSearchResults__filters__useFiltersButton {
        display: none;
    }
}

.productSearchResults__filterContainer[data-ismobilefilteropen='true'] {
    @include device('tablet') {
        display: block;
        position: fixed;
        top: 0;
        bottom: 0;
        z-index: 100;
        right: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.3);

        .productSearchResults__filters__header {
            display: flex;
            height: 15%;
            align-items: center;
            justify-content: space-between;
            padding: 0.5rem 0;
            margin: 0 1rem 0.5rem 1rem;
            border: none;
            border-bottom: 1px solid #dfdfdf;

            > h2 {
                margin: 0;
                text-transform: none;
                letter-spacing: 0;
                z-index: 101;
            }

            > button {
                color: #F86800;
                background-color: white;
                border: none;
                font-size: 1rem;
                padding: 0.25rem;
                text-transform: none;
                letter-spacing: 0;
            }

            > button:active,
            > button:focus {
                color: white;
                background-color: #F86800;
            }
        }

        .productSearchResults__filters {
            display: block;
        }

        .productSearchResults__filters__useFiltersButton {
            display: block;
            width: 96%;
            padding: 1rem;
            font-size: 1rem;
            text-transform: none;
            border: none;
            border-top: 1px solid #dfdfdf;
            color: #F86800;
            z-index: 101;

            &:hover {
                color: white;
            }
        }
    }
}

.productSearchResults__filters__container {
    height: 60%;
    & > * {
        min-width: 279px;
        max-width: 279px;
        padding: 0.25rem 0.5rem;

        @include device('tablet') {
            max-width: unset;
            min-width: unset;
            width: auto !important;
        }
    }

    & > *:not(:last-child) {
        margin-bottom: 1rem;
    }

    @include device('tablet') {
        overflow-y: scroll;
        margin-bottom: 0 !important;
    }
}
