@import 'definitions';

.notification {
	background-color: $grey-80;
    display: flex;
	padding: 1rem 0;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
	width: 100%;
	z-index: 200;
	top: 0;
    left: 0;
	position: fixed;
}

.notificationBody {
    margin: 0 2rem;
    color: $white;
    position: relative;

    @include device('tablet') {
        margin: 0 1rem;
    }
}

.notificationClose {
    @include --link;
    border-radius: 3px;
    color: $orange;
    background-color: $charcoal-grey;
    border: solid 1px $orange;
    text-align: center;
    margin-right: 2rem;

    @include device('tablet') {
        margin: 0 1rem;
    }
}
