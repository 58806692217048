/*
Breakpoints:
320px Smartphones portrait
480px Smartphones landscape
768px Tablet portrait
1024px Tablet landscape (& laptops ->)
1440px Desktops
 */
 $breakpoints: (
    'xs': 321px,
    's': 426px,
    'sm': 650px,
    'm': 769px,
    'header': 1025px,
    'l': 1025px,
    'xl': 1281px,
    'xxl': 1441px,
);
 @mixin breakpoint($size) {
    @if map-has-key($breakpoints, $size) {
        @media only screen and (min-width: map-get($breakpoints, $size)) {
            @content;
        }
    }
     @else {
        @warn "Couldn't find breakpoint for `#{size}`. "
            + "Available breakpoints are: #{map-keys($breakpoints)}.";
    }
}

$devices: (
    'mobile': 425px,
    'tablet': 768px,
    'desktop': 1024px,
    'desktop-l': 1440px,
);
 @mixin device($size) {
    @if map-has-key($devices, $size) {
        @media only screen and (max-width: map-get($devices, $size)) {
            @content;
        }
    }
     @else {
        @warn "Couldn't find breakpoint for `#{$size}`. "
            + "Available devices are: #{map-keys($devices)}.";
    }
}
