@import 'definitions';

.link {
    max-width:100%;
    box-sizing: border-box;
}

.card {
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    background-color: $white;
    transition: all .1s ease-in;
    border-radius: 3px;
    height: 270px;
    border: 1px solid #e1e1e1;


    &:hover {
        background-color: $white;
        box-shadow: 0 3px 10px 0 rgba(0, 0, 0, .50);
    }

    @include device('tablet') {
        height: 270px;
    }

    @include device('mobile') {
        max-height: 100px;
        flex-direction: row;
        border: none;
        border-bottom: 1px solid #e1e1e1;
    }
}

.cardHeaderContainer {
    height: 115px;

    @include device('mobile') {
        width: 100px;
        height: 100%;
    }
}

.cardHeader {
    width: 100%;
    height: 115px;
    object-fit: cover;
    border-radius: 3px;
    flex-shrink: 0;

    @include device('mobile') {
        width: 100px;
        height: 100%;
    }
}

.cardBody {
    color: $grey-80;
    display: flex;
    flex-direction: column;
    padding: 0.5rem;
    overflow: hidden;
}

.cardTitle {
    margin-top: 0;
    margin-bottom: .5rem;
    text-align: left;
    font-size: 1rem;
    line-height: 1.1;

    @include device('mobile') {
        font-size: $paragraph-font-size-mobile;
    }
}

.cardLead {
    @include --s-paragraph;
    margin-top: 0;
    line-height: initial;
    text-align: left;

    @include device('mobile') {
        margin-bottom: 20px;
        display: none;
    }
}
