@import 'definitions';

.contact {
    text-transform: none;
    color: $white;
}

.contactField {
    display: flex;
    margin-top: 20px;
}

.content {
    margin-left: 20px;
    overflow-wrap: break-word;
    word-break: break-word;

    & > h4 {
        margin-top: 0;
    }
}

.info {
    font-family: SkattaSansUI-Regular;
    font-size: 14px;
    font-stretch: normal;
    line-height: 2.0;
    letter-spacing: 0.3px;
    text-align: left;
    color: $white;
    margin: 0;
    white-space: pre-wrap;
}
