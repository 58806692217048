@import 'definitions';

$base-z-index: 1;

.page {
    margin-bottom: -40px;

    @include device('tablet') {
        & > h2 {
            margin-bottom: 1rem;
        }
    }
}

.heroContainer {
    max-height: 400px;
   @include device('tablet') {
        max-height: unset;
    }
}

.heroTitle {
    color: $white;
}

.headingMobileStyle {
    @include device('tablet') {
        margin-top: 24px;
    }
}

.heroTitleLink {
    color: unset;
}

.hideHeroInMobile {
    @include device('tablet') {
        display: none;
    }
}

.lead {
    margin-bottom: 60px;
    margin-top: 20px;
    @include device('tablet') {
        font-size: 16px;
        margin-top: 0px;
        margin-bottom: 48px;
    }
}

.leadCategorySelected {
    @include device('mobile') {
        margin-bottom: 30px;
    }
}

.productTitle {
    margin-top: 0;
    margin-bottom: 15px;
}

.productList {
    z-index: $base-z-index;
}

.resultContainer {
    display: flex;
    flex-direction: column;
}

.resultCount {
    margin: 0;
    margin-bottom: 10px;
    text-align: center;
}

.searchContainer {
    max-width: 100%;
    margin: 2rem;
    z-index: calc($base-z-index + 1);

    @include device('mobile') {
        margin: 1rem;
    }
}

.searchContainerFrontPage {
    margin-top: -29px;
}

.contentContainer {
    display: flex;
    flex-direction: row;
}
