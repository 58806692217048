@import 'definitions';

.swiperRowWrapper {
	width: 100% !important;

	@include device('tablet') {
		div[class^="swiper swiper-initialized swiper-horizontal"] {
			padding-top: 10px;
			padding-bottom: 10px;

			// To get the swiper width 100% of the screen
			width: 100vw;
			position: relative;
			left: calc(-50vw + 50%);

			box-sizing: border-box;
			padding-left: 25px;
			border-left: 25px;
		}
	}
}

.showOnlyOnMobile {
	@include breakpoint('m') {
		display: none !important;
	}
}

.hideOnMobile {
	@include device('tablet') {
		display: none !important;
	}
}

.rowWrapper {
	width: 100%;
}

.row {
	$gutter: 1rem;
	display: flex;
	margin: -$gutter;
	justify-content: center;
	align-items: flex-start;
	@include device('desktop') {
		flex-wrap: wrap;
	}
	@include device('mobile') {
		align-items: stretch;
		flex-direction: column;
		flex-wrap: nowrap;
	}

	.rowContentWrapper {
		margin: $gutter;
		display: flex;
		justify-content: center;
		@include device('desktop') {
			flex-basis: calc(50% - 2*$gutter);
			max-width: calc(50% - 2*$gutter);
			&:only-child {
				max-width: none;
				flex-basis: auto;
			}
		}
		@include device('tablet') {
			flex-basis: auto;
			max-width: none;
		}
		flex: 1 1;
	}
}
.contentHorizontallyOnMobile {
	width: 100%;
	height: 100%;
	align-items: normal;
	word-break: break-word;

	a {
		hyphens: auto;
	}
}
