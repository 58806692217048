@import 'definitions';

.filterCard {
    margin-left: 0;
    padding: 0.5rem;
    margin-bottom: 15px;
    border-radius: 5px;
    font-size: 0.9rem;
}

.filterCardExtended {
    background-color: rgba(0, 0, 0, 0.03);

    @include device('tablet') {
        background-color: white;
    }

    @include device('mobile') {
        background-color: white;
    }
}

.filterHeaderRow {
    cursor: pointer;
    border-bottom: 2px solid $orange;
    padding-right: 0px;
    margin-bottom: 0.5rem;
    span {
        display: inline-block;
        white-space: nowrap;
    }
    font-size: 16px;
    color: #25282A;
    font-family: SkattaSansUI-Black;
}

.filterHeaderPrimary {
    min-width: 23px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.chevron {
    color: $orange;
    display: flex;
    margin-left: auto;
    svg {
        margin-right: -5px;
    }
}

.optionRowContainer {
    width: 100%;
}

.optionRow {
    cursor: pointer;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    align-items: center;
}

.filterOptionLabel {
    padding-left: 0.6rem;
    cursor: pointer;
}

.filterOptionRadio {
    cursor: pointer;
    -webkit-appearance: none;
    width: 18px;
    height: 18px;
	background-color: $white;
	border: 1px solid #cacece;
	padding: 5px;
	border-radius: 50px;
	display: inline-block;
	position: relative;
}

.filterOptionRadio:checked:after {
    content: ' ';
	width: 12px;
	height: 12px;
	border-radius: 50px;
	position: absolute;
	top: 2px;
	background: $orange;
	left: 2px;
}

.filterOptionRadio:hover {
    border: 1px solid $orange;
}

.filterOptionRadio:checked {
	background-color: $white;
	border: 1px solid $orange;
}

.filterOptionRadio:active, .filterOptionRadio:checked:active {
	box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px 1px 3px rgba(0,0,0,0.1);
}
