@import 'breakpoints';

/* Colors */
$accessibility-kb-focus: var(--swiper-theme-color);
$orange: #F86800;
$orange2: #E7702D;
$darker-orange: #CB4700;
$charcoal-grey: #31313d;
$faded-red: #db3842;
$bright-red: #FF0000;
$blue-80: #00205B;
$black: #000000;
$grey-80: #282828; // this should be used in black texts
$white: #ffffff;
$white-two: #f8f8f8;
$night-blue: #2e2e3b;
$light-grey: #54585A;
$medium-grey: #707070;
$darker-grey: #3E3E3E;
$lighter-grey: #dfdfdf;

$light-grey2: #DDDDDD;
$lighter-grey2: #FAFAFA;
$medium-grey2: #757575;
$bubble-grey: #565656;

$brown: #614700;
$light-yellow: #f7f3e5;

/* Typography */
$paragraph-font-size: 1rem;
$s-paragraph-font-size: calc(14rem / 18);
$lead-font-size: calc(24rem / 18);
$link-font-size: calc(12rem / 18);
$detail-font-size: calc(10rem / 18);
$tag-font-size: calc(10rem / 18);
$paragraph-font-size-mobile: calc(16rem / 18);
$s-paragraph-font-size-mobile: calc(14rem / 18);
$lead-font-size-mobile: calc(20rem / 18);
$link-font-size-mobile: calc(12rem / 18);
$detail-font-size-mobile: calc(10rem / 18);
$tag-font-size-mobile: calc(10rem / 18);

$h1-font-size-xs: 1.4rem;
$h1-font-size-s: 1.55rem;
$h1-font-size-m: 1.7rem;
$h1-font-size-l: 3rem;
$h1-font-size-xl: 4rem;

$h2-font-size-xs: calc(#{$h1-font-size-xs} / 1.22);
$h2-font-size-s: calc(#{$h1-font-size-s} / 1.27);
$h2-font-size-m: calc(#{$h1-font-size-m} / 1.33);
$h2-font-size-l: calc(#{$h1-font-size-l} / 1.68);
$h2-font-size-xl: calc(#{$h1-font-size-xl} / 1.8);

$h3-font-size-xs: calc(#{$h1-font-size-xs} / 1.4);
$h3-font-size-s: calc(#{$h1-font-size-s} / 1.6);
$h3-font-size-m: calc(#{$h1-font-size-m} / 2.0);
$h3-font-size-l: calc(#{$h1-font-size-l} / 2.5);
$h3-font-size-xl: calc(#{$h1-font-size-xl} / 3.0);

$h4-font-size-xs: calc(#{$h1-font-size-xs} / 1.7);
$h4-font-size-s: calc(#{$h1-font-size-s} / 2.3);
$h4-font-size-m: calc(#{$h1-font-size-m} / 3.0);
$h4-font-size-l: calc(#{$h1-font-size-l} / 4.0);
$h4-font-size-xl: calc(#{$h1-font-size-xl} / 5.0);

/* Additional constants */
$smallHeaderHeight: 50px;
$largeHeaderHeight: 85px;
$headerFooterContentMaxWidth: 1440px;
$contentWidthTablet: 87.5%;
$contentWidth: 52%;

@mixin --lead {
    font-family: SkattaSansUI-Medium;
    font-size: 20px;
    line-height: 1.5;
    letter-spacing: calc(0.6rem / 18);
    text-align: center;
    @include device('tablet') {
        font-size: $lead-font-size-mobile;
    }
}

@mixin --paragraph {
    font-family: SkattaSansUI-Regular;
    font-size: $paragraph-font-size;
    line-height: 1.56;
    letter-spacing: 0.5px;
    color: $grey-80;
    @include device('tablet') {
        font-size: $paragraph-font-size-mobile;
    }
}

@mixin --s-paragraph {
    font-family: SkattaSansUI-Regular;
    font-size: $s-paragraph-font-size;
    line-height: 2.14;
    text-align: left;
    @include device('tablet') {
        font-size: $s-paragraph-font-size-mobile;
    }
}

@mixin --link {
    font-family: SkattaSansUI-Regular;
    font-size: $link-font-size;
    line-height: 2.33;
    letter-spacing: calc(1.5rem / 18);
    text-align: center;
    text-transform: uppercase;
    color: $orange;
    @include device('tablet') {
        font-size: $link-font-size-mobile;
    }
    &:hover {
        color: $orange;
    }
}

@mixin --navLink {
    font-family: SkattaSansUI-Regular;
    text-transform: none;
    letter-spacing: 0px;
    line-height: 16px;
    font-size: 16px;
    color: $white;
}

@mixin --navBarLinkFocus {
    outline: 2px solid $black;
    border-radius: 4px;
}

@mixin --header-link {
    font-family: SkattaSansUI-Regular;
    font-size: $link-font-size;
    line-height: 2.33;
    letter-spacing: calc(1.5rem / 18);
    text-align: center;
    text-transform: uppercase;
    color: $white;
    @include device('tablet') {
        font-size: $link-font-size-mobile;
    }
    &:hover {
        color: $light-grey;
    }
}

@mixin --detail {
    font-family: SkattaSansUI-Medium;
    font-size: $detail-font-size;
    line-height: 2.8;
    letter-spacing: calc(0.3rem / 18);
    text-align: right;
    text-transform: uppercase;
    @include device('tablet') {
        font-size: $detail-font-size-mobile;
    }
}

@mixin --tag {
    font-family: SkattaSansUI-Medium;
    font-size: $tag-font-size;
    letter-spacing: calc(0.3rem / 18);
    text-align: center;
    text-transform: uppercase;
    @include device('tablet') {
        font-size: $tag-font-size-mobile;
    }
}

@mixin --button {
    font-size: 0.9rem;
    line-height: 2.33;
    text-align: center;
    background-color: $white;
    color: $orange;

    padding: 3px 20px;
    border-radius: 3px;
    border: solid 1px $orange;
    transition: all .1s ease-in;
    cursor: pointer;
    &:hover {
        background-color: $orange;
        color: $white;
    }
}

@mixin --bubbleButton($color, $border) {
    display: flex;
    text-align: center;
    font-size: 14px;
    border-radius: 24px;
    margin: 5px;
    padding: 8px 20px;
    color: $color;
    border: $border;
    font-weight: 600;
}

@mixin --highPriorityButton {
    font-family: SkattaSansUI-Medium;
    font-size: 0.66667rem;
    line-height: 2.33;
    letter-spacing: 0.08333rem;
    text-align: center;
    color: $white;
    background-color: $orange;
    text-transform: uppercase;
    padding: 3px 20px;
    border-radius: 3px;
    border: solid 1px $orange;
    transition: all .1s ease-in;
    cursor: pointer;
}

@mixin --searchResultContainer {
    display: flex;
    flex-direction: column;
    flex-basis: 69.75%;
    @include device('desktop') {
        flex-basis: calc((100% - (3 * 3vw)) * (2 / 3));
    }

    @include device('tablet') {
        flex-basis: auto;
    }
}

@mixin --loadingIndicatorWithScroll {
    padding-top: calc(var(--vh, 1vh)*30 - #{$largeHeaderHeight});
    height: calc(var(--vh, 1vh)*70 - #{$largeHeaderHeight});
    @include breakpoint('header') {
        padding-top: calc(var(--vh, 1vh)*30 - #{$smallHeaderHeight});
        height: calc(var(--vh, 1vh)*70 - #{$smallHeaderHeight});
    }
}

@mixin --checkedRadioButton {
    width: 14px;
    height: 14px;
    background-color: $orange;
    top: 3px;
    bottom: 0;
    left: 3px;
    right: 0;
    position: absolute;
    content: '';
    border-radius: 50%;
}

@mixin --filterTitle {
    height: 28px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    text-transform: uppercase;
    font-size: 16px;
    padding: 0;
    border: 0;
    border-bottom: 2px solid $orange !important;
    background-color: transparent;
    h3 {
        font-family: SkattaSansUI-Black;
        color: $grey-80;
        margin-top: 0;
    }

    svg {
        margin-right: 8px;
        fill: $orange !important;
    }
}
@mixin --kButton {
    font-weight: normal !important;
    text-transform: none;
    font-family: SkattaSansUI-Medium !important;
}

@mixin --h2Text {
    font-weight: initial;
    font-family: SkattaSansUI-Black;
    font-size: 32px;
    letter-spacing: calc(1.4rem / 18);
    text-align: center;
    line-height: 1.3em;

    @include device('tablet') {
        font-size: 20px;
    }
}
