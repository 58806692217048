@import 'definitions';

.loading {
    @include --loadingIndicatorWithScroll;
}

.recipeContainer {
    display: flex;
    flex-direction: row;
    div {
        width: 50%;
    }

    @media only screen and (min-width: 1023px) {
        div {
            flex: 1;
        }
        div[class*='ingredientsLayout'] {
            max-width: 520px;
        }
    }
    p {
        font-size: 16px;
    }
 
    @include device('tablet') {
       flex-direction: column;
       div {
        width: 100%;
    }
    }
}