@import 'definitions';

.radioTree {

}

.treeItem {
    width: 100%;

    &:hover {
        background-color: $white-two;
    }
}

.itemRadio, .itemToggle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
}

.itemLabel {
    @include --s-paragraph;
    flex: 1;
    white-space: nowrap;
    cursor: pointer;

    &.selected {
       font-family: SkattaSansUI-Medium;
    }
}

.radioOuter {
    position: relative;
    cursor: pointer;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    border: 1px solid #cacaca;
}

.radioInner {
    position: absolute;
    top: 5px;
    left: 5px;
    width: 8px;
    height: 8px;
    border-radius: 50%;

    &.selected {
        background-color: $orange;
    }
}

.radioToggle {
    @include --lead;
    color: $orange;
    text-align: center;
    cursor: pointer;
    width: 40px;
}
