.contactInfoDescription {
    margin: 0 auto;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.contactInfoDescription__container {
    margin-bottom: 1rem;
}

.contactInfoDescription__paragraph {
    margin: 0;
    margin-bottom: 1rem;
}

.contactInfoDescripion__expandButton {
    display: none;
    border: none;
}

.contactInfoDescription__richTextDescription > *:first-child {
    margin-top: 0;
}

@media only screen and (max-width: 769px) {
    .contactInfoDescripion__expandButton {
        display: block;
        border: none;
        align-self: center;
        margin-bottom: 16px;
    }

    .contactInfoDescription__container[data-overflow='true'] {
        max-height: 150px;
        overflow: hidden;
        mask-image: linear-gradient(to bottom, black 50%, transparent 100%);
    }
}
