@import 'definitions';

.cardContainer {
    align-self: stretch;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: stretch;
    margin: 0;

    @include device('desktop') {
        justify-content: space-evenly;
    }

    @include device('mobile') {
        margin-bottom: 2rem;
    }
}

.cardWrapper {
    max-width: 306px;

    @include breakpoint('l') {
        width: calc((100% - ( 2 * 3vw )) / 3);
        margin-bottom: 3vw;

        &:last-child:nth-child(3n+2) {
            margin-left: 3vw;
            margin-right: auto;
        }
    }

    @include breakpoint('xl') {
        margin-bottom: 40px;
    }

    @include device('desktop') {
        width: calc((100% - ( 1 * 3vw )) / 2);
        margin-bottom: 3vw;
    }

    @include device('tablet') {
        width: calc((100% - ( 3 * 3vw )) / 2);

        &:last-child:nth-child(2n+1) {
            margin-left: 3vw;
            margin-right: auto;
        }
    }

    @include device('mobile') {
        width: 100%;
        margin: 0;
        max-width: initial;

        &:last-child:nth-child(2n+1), &:last-child:nth-child(3n+1) {
            margin: 0;
        }
    }
}

.loading {
    @include --loadingIndicatorWithScroll;
}
