@import 'definitions';
.button {
    margin: 16px 36px;
    text-decoration: none !important;
    font-weight: normal !important;
}

.wordBreak {
    word-break: break-word;
    border-radius: calc(0.47 * 290px) !important;
    height: auto !important;

    a, span {
        padding: 12px 0px;
        white-space: normal;
    }
}

.primary {
    @include --kButton;
    color: $white;
}

.secondary {
    @include --kButton;
}

.tertiary {
    font-size: 14px;
    padding: 5px 15px;
}
