@import 'definitions';

.recipeResultContentArea {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    justify-content: space-around;
}

.desktop {
    display: none;

    @include breakpoint('m') {
        display: block;
    }
}

.recipeFilterContainer {
    display: flex;
    flex-grow: 1;
    flex-shrink: 1;
    @include breakpoint('l') {
        flex-basis: calc(( 100% - (3 * 3vw) ) / 4);
        max-width: calc(( 100% - (3 * 3vw) ) / 4);
        min-width: 290px;
    }

    @include device('desktop') {
        flex-basis: calc(( 100% - (4 * 3vw) ) / 3);
        max-width: calc(( 100% - (4 * 3vw) ) / 3);
        min-width: 290px;
    }

    @include device('tablet') {
        flex-basis: 100%;
        max-width: 100%;
        min-width: 290px;
        display: none;
    }
}

.mobile {
    display: block;

    @include breakpoint('m') {
        display: none;
    }
}

.filterCardModal {
    background: rgba(0,0,0,0.35);
    width: 100vw;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.filterCardModalContents {
    background: white;
    width: 100vw;
    overflow: scroll;
    position: fixed;
    top: 60px;
    left: 0;
    right: 0;
    bottom: 0;
    border-left: 4vw solid $medium-grey;
    z-index: 2;
    box-sizing: border-box;
}

.recipeFilterMobileHeader {
    padding: 0.5rem 0;
    margin: 0 1rem 0.5rem 1rem;
    border: none;
    border-bottom: 1px solid $lighter-grey;

    h2 {
        margin: 0;
        text-transform: none;
        letter-spacing: 0;
    }

    .clearFilterButton {
        color: $orange;
        border: none;
        font-size: 1rem;
        padding: 0.25rem;
        text-transform: none;
        letter-spacing: 0;
    }

    .clearFilterButton:hover {
        color: white;
    }
}

.useFilterButtonContainer {
    position: fixed;
    bottom: 0;
    width: 96vw;
    left: 4vw;
    background-color: white;
}

.useFilterButton {
    width: 100%;
    padding: 1rem;
    margin: 0 1rem;
    font-size: 1rem;
    text-transform: none;
    border: none;
    border-top: 1px solid $lighter-grey;
    color: $orange;
}

.useFilterButton:hover {
    color: white;
}

.container {
    @include --searchResultContainer;
}

.recipeSearchFilterContainer {
    order: -1;
    @include device('tablet') {
        padding: 0 1rem;
    }

    @include device('mobile') {
        padding: 0 1rem;
    }
}

.recipeFilterMobileContainer {
    margin-bottom: 5rem;
}

.filterBubbleContainer {
    text-transform: uppercase;
    padding-bottom: 1rem;
    div {
        margin-right: 0.5rem;
        margin-bottom: 0.5rem;
    }
}

.erroredFilterBubble {
    color: $bright-red!important;
    border: 1px solid $bright-red!important;
    text-transform: uppercase;
}

.helpTextContainer {
    background-color:  #fafafa;
    margin-top: 2rem;
    @include device('mobile') {
        margin-bottom: 1rem;
    }
    padding: 2rem 0;
    p {
        margin: 0.5rem;
        span {
            color: #E7702D;
            cursor: pointer;
            text-decoration: underline;
        }
    }
}

.recipeSearchResultsContainer {
    order: -1;
}

.seoTextContainer {
    margin-top: 2rem;

    h1 {
        font-size: 1.5rem;
        text-align: left;
        text-transform: none;
        margin: 0;
        margin-bottom: 1.0rem;
        line-height: 1.56;
    }
    p {
        margin: 0;
    }
}
