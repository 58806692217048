@import 'definitions';

.productBrandsSection {
    border-top: 1px dashed #e1e1e1;
    padding: 1.5rem 0 1rem 0;
}

.productBrandsSection__heading {
    font-size: 1.5rem;
    text-align: left;
    margin: 0;
}

.productBrandsSection__list {
    list-style: none;
    padding: 0;
    margin: 1rem 0 0 0;
}

.productBrandsSection__list__item {
    display: inline-block;
    width: 25%;
    padding: 0 0.125rem 0.5rem 0;
    vertical-align: top;
    box-sizing: border-box;
    text-overflow: ellipsis;
    overflow: hidden;

    @include device('tablet') {
        width: 50%;
    }

    @include device('mobile') {
        width: 100%;
    }
}

.productBrandsSection__list__item > a {
    color: $grey-80;
    text-transform: uppercase;
}

@media only screen and (max-width: 860px) {
    .productBrandListContainer {
        padding: 0 0.5rem;
    }

    .productBrandsSection {
        padding: 1rem 0;
    }
}