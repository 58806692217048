@import 'definitions';

.mobileMenuContainer {
    height: calc(var(--vh, 1vh)*100 - #{$smallHeaderHeight});
    position: fixed;
    top: $smallHeaderHeight;
    left: 0;
    width: 100%;
    flex-shrink: 0;
    flex-grow: 0;
    z-index: 12;
}

.mobileMenu {
    background-color: $orange;
    overflow: auto;
    -webkit-overflow-scrolling: scroll;
    display: flex;
    flex-shrink: 0;
    flex-grow: 1;
    flex-direction: column;
    flex-basis: 85%;
    height: 100%;
}

.menuLinks {
    display: flex;
    flex-shrink: 0;
    flex-grow: 0.3;
    justify-content: space-around;
    flex-direction: column;
    flex-wrap: nowrap;
    border-top: 1px solid $white;
    padding: 10px 16px;
}

.linkItem {
    display: flex;
    flex-shrink: 1;
    flex-grow: 1;
    align-items: center;
    justify-content: flex-start;
    color: $white;
    margin: 8px 0;
}

.loginLinkItem{
    display: flex;
    flex-shrink: 1;
    flex-grow: 1;
    align-items: center;
    justify-content: flex-start;
    color: $white;
    margin: 8px 0;
    margin-bottom: 12px !important;
}

.linkText {
    margin: 0 10px;
}

.logInLinkText {
    color: $white;
    font-size: 18px !important;
    line-height: 26px;
    margin: 0 10px;
}

.becomeClientContainer {
    flex-grow: 1;
    flex-shrink: 0;
}

.languageContainer {
    flex-shrink: 0;
}

.becomeClientLink {
    display: flex;
    border: 1px solid $white;
    border-radius: 4px;
    padding: 10px 20px;
    color: $white;
    margin-bottom: 16px;
}

.languageLink {
    font-family: SkattaSansUI-Medium;
    color: $white;
    flex-grow: 1;
    text-align: center;
    margin-bottom: 1rem;
    border: 1px solid $white;
    text-transform: uppercase;
    padding: 10px 0;
    font-size: 14px;
    &.languageActive {
        background-color: $white;
        color: $orange;
    }
}

.active {
    &:before {
        width: 4px;
        height: calc(100% - 8px);
        background-color: $white;
        border-radius: 0 2px 2px 0;
        content: "";
        position: relative;
        top: 0;
        left: -16px;
    }
}

.activeSubMenuItem {
    &:before {
        width: 4px;
        height: calc(100% - 8px);
        background-color: $white;
        border-radius: 0 2px 2px 0;
        content: "";
        position: relative;
        left: -30px;
        top: 0;
    }
}

.menuMainNav, .menuSecondaryNav {
    display: flex;
    flex-grow: 0.5;
    flex-shrink: 0;
    justify-content: space-around;
    flex-direction: column;
    padding: 10px 0;
    border-top: 1px solid $white;
}

.menuSecondaryNav {
    flex-grow: 0.4;
}

.openSubNav {
    background-color: #ce5000;
    border-radius: 4px;
    margin: 0 4px;
}

.navChevron {
    color: $white;
    margin-left: auto;
    display: flex;
    align-items: center;
    transition: transform .2s ease-in-out;
    &.up {
        transform: rotate(180deg);
    }
}

.subNavContainer {
    flex-grow: 1;
    flex-shrink: 0;
}

.navLinkWrapper {
    flex-grow: 1;
}

.mainNavLink, .secondaryNavLink {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 16px;
    flex-grow: 1;
    flex-shrink: 0;
    color: $white;
    cursor: pointer;
}

.mainNavLink, .linkItem {
    font-size: 18px;
    line-height: 26px;
}

.mainNavLink {
    &:hover {
        background-color: #ff8825;
    }
}

.secondaryNavLink {
    font-size: 14px;
    line-height: 24px;
    text-transform: uppercase;
}

.mainNavLinkSubMenuOpen {
    border-radius: 4px 4px 0 0;
    padding: 8px 12px;
    &:hover {
        background-color: #a03800;
    }
}

.subNavLink {
    @include --navLink;
    height: 34px;
    display: flex;
    align-items: center;
    padding-left: 30px;
    flex-shrink: 0;

    &:hover {
        background-color: #a03800;
    }
}

.transparent {
    opacity: 0;
    display: flex;
    flex-shrink: 0;
    flex-grow: 1;
}

.hidden {
    visibility: hidden;
}
