@import 'definitions';

.cardContainer {
    align-self: stretch;
    display: flex;
    flex-flow: row wrap;
    justify-content: stretch;
    align-items: stretch;
    margin: 1rem 0;

    @include device('tablet') {
        justify-content: center;
    }
}

.smallCardContainer {
    width: calc(100%/3);

    @include device('tablet') {
        width: 100%;
    }
}
