@import 'definitions';

.filterBar {
    display: flex;
    flex-flow: column nowrap;
    width: 90%;
}

.filters {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    &.navbar {
        width: auto;
        flex-basis: 100%;
        box-sizing: border-box;
        justify-content: flex-start;
        & .quickFilterBase {
            color: $orange;
        }
        @include device('tablet') {
            flex-flow: column;
            align-items: center;
            overflow-x: scroll;
            max-width: 100%;
            & .quickFilterBase {
                white-space: nowrap;
                margin: 0 auto;
            }
        }
    }
}

.navbarWidth {
    width: auto;
    max-width: 100%;
    flex: 1 1;
}

.quickFilterBase {
    @include --link;
    text-align: justify;
    text-transform: uppercase;

    display: inline-block;
    position: relative;
    margin: 0 2%;
    &:hover {
        color: $orange;
    }
}

.quickFilter {
    color: $grey-80;
}

.quickFilterWithHero {
    color: $white;
}

.subFilterLine {
    margin: 20px 20% 0;
    padding-top: 20px;
    border-top: dashed 1px rgba(0, 0, 0, 0.30);
}

.active {
    color: $orange;

    &:after {
        content: "";
        width: 60%;
        border-top: 2px solid $orange;
        display: block;
        margin: 0 auto;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
    }
}

.activeWithHero {
    color: $white;

    &:after {
        content: "";
        width: 60%;
        border-top: 2px solid $white;
        display: block;
        margin: 0 auto;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
    }
}

.showInMobile {
    max-width: 100%;
    @include breakpoint('m') {
        display: none!important;
    }
}

.hideInMobile {
    @include device('tablet') {
        display: none;
    }
}
