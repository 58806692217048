@import 'definitions';

.loading {
    @include --loadingIndicatorWithScroll;
    display: flex;
    justify-content: center;
}

.productCardWrapper {
    display: flex;
    justify-content: center;
    margin-bottom: -40px;
    font-family: "SkattaSans";
}
