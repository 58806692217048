.showMore {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.showMore__container {
    position: relative;
    width: 100%;
}

.showMore__expandButton {
    display: none;
}

@media only screen and (max-width: 860px) {
    .showMore__container[data-overflow='true'] {
        max-height: calc(1.56 * 16px * 3); // Line height * font-size * displayed description rows
        overflow: hidden;
    }

    .showMore__container[data-overflow='true'] > .showMore__container__mask {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        height: calc(1.56 * 16px * 3);
        background: linear-gradient(to top, white, rgba(255,255,255,0.05) 100%);
    }

    .showMore__expandButton {
        display: block;
        margin-top: 1rem;
        border: none;
    }
}
