@import 'definitions';

.cardWrapper {
    box-sizing: border-box;
    flex-shrink: 0;
    flex-grow: 1;
    /* calc() doesn't work with flex basis on IE10 */
    /* 100 % / n, where n = number of cards in product list row */
    flex-basis: calc(100% / 4);
    max-width: calc(100% / 4);
    margin-bottom: 1rem;

    @include device('desktop') {
        flex-basis: calc(100% / 2);
        max-width: calc(100% / 2);
    }

    @include device('tablet') {
        flex-basis: calc(100% / 3);
        max-width: calc(100% / 3);
        position: relative;
        margin-bottom: 0.5rem;
    }

    @include device('mobile') {
        flex-basis: calc(100% / 2);
        max-width: calc(100% / 2);
    }

    &.oneCard {
        border-right: none;

        flex-basis: calc(100% / 3);
        flex-grow: unset;

        @include device('desktop') {
            flex-grow: 1;
        }

        @include device('tablet') {
            flex-basis: calc(100% / 2);
            max-width: calc(100% / 2);
        }

        @include device('mobile') {
            flex-basis: calc(100% / 1);
            max-width: calc(100% / 1);
        }
    }

    &.twoCards {
        flex-basis: calc(100% / 3);
        flex-grow: unset;

        @include device('tablet') {
            flex-basis: calc(100% / 2);
            max-width: calc(100% / 2);
        }
    }

    &.threeCards {
        flex-basis: calc(100% / 3);

        @include device('tablet') {
            &:nth-child(3) {
                border-right: none;
            }
        }

        @include device('mobile') {
            flex-basis: calc(100% / 2);
            max-width: calc(100% / 2);
        }
    }

    &.twoCardsTablet {
        @include device('tablet') {
            flex-basis: calc(100% / 2);
        }
    }
}

// The productCards were too close to each other between this range
// so needed to set a different style for it
@media only screen and (min-width: 1024px) and (max-width: 1217px) {
    .cardWrapper {
        flex-basis: calc(100% / 3);
        max-width: calc(100% / 3);
    }
}

@media only screen and (max-width: 563px) {
    .cardWrapper {
        border-right: none !important;
    }
}

.productCard {
    box-sizing: border-box;
    height: 100%;
    min-width: 218px;
    background-color: $white;
    transition: all .1s ease-in;
    position: relative;
    margin: 0 1rem 1rem 0;
    z-index: 2;

    &:hover {
        box-shadow: 0 2px 8px 0 rgba(0, 0, 0, .50);
        z-index: 3;
    }

    @include device('tablet') {
        min-width: unset;
        margin-left: 0.5rem;
        margin-right: 0.5rem;
        margin-bottom: 1rem;
        padding: 0 0.5rem;
    }

    @media only screen and (max-width: 600px) {
        margin-left: 0;
        margin-right: 0;
        padding: 0 0.375rem;
    }
}
.pictureContainer{
    background-color: rgba(0,0,0,0.04); // Visible during loading
    height: 180px;
}

.iconCardHeader {
    width: 100%;
    height: 180px;
}

.cardHeader {
    width: 100%;
    height: 100%;
    border-radius: 3px;
    object-fit: cover;

    @include device('mobile') {
        height: 140px;
    }
}

.noPic {
    color: $white;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    background-color: lightgray;
}

.iconContainer {
    align-self: center;
    margin-top: -15px;
    width: 44px;
    height: 44px;
    color: $grey-80;
    background-color: $white;
    border-radius: 50%;

    @include device('tablet') {
        display: none !important;
    }
}

.cardBody {
    margin-top: -1rem;
    color: $grey-80;
    -webkit-hyphens: auto;
    overflow-wrap: break-word;
    hyphens: auto;
}

.cardTitle {
    font-size: 14px;
    line-height: 1.43;
    margin-top: 5px;
    margin-bottom: 1.5rem;
    padding: 0 1.2rem;
    text-align: center;
    max-width: 100%;

    @include device('desktop') {
        font-size: 12px;
    }

    @include device('tablet') {
        color: rgb(37, 40, 42);
        font-size: 14px;
        margin-bottom: 0;
        text-align: left;
        padding: 0;
    }
}

.cardType {
    font-family: SkattaSansUI-Medium;
    font-size: 8px;
    margin: 0;
    margin-top: .5rem;
    text-align: center;
    max-width: 100%;

    @include device('tablet') {
        margin-top: 1.5rem;
        text-align: left;
        text-transform: uppercase;
        font-size: 10px;
        color: rgb(117, 117, 117);
    }
}

.productClassIcon {
    display: none;
    @include device('tablet') {
        display: block;
        position: absolute;
        top: 5px;
        right: 10px;
        color: $grey-80;
    }
}
