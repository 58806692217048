@import 'definitions';
$grid-gap: 24px;
$repeat-count-desktop: min(var(--min-repeat-count), 4);
$repeat-count-tablet: min(var(--min-repeat-count), 3);
$repeat-count-mobile: min(var(--min-repeat-count), 2);

.container {
    margin-bottom: 3em;
}

.cardWrapper {
    display: grid;
    grid-template-columns: repeat($repeat-count-desktop, calc(100% / $repeat-count-desktop - $grid-gap * $repeat-count-desktop));
    grid-gap: $grid-gap;
    grid-auto-flow: row;
    justify-content: center;
    margin-top: 32px;

    @include device('desktop') {
        grid-template-columns: repeat($repeat-count-tablet, calc(100% / $repeat-count-tablet - $grid-gap * $repeat-count-tablet));
    }
    @include device('tablet') {
        grid-template-columns: repeat($repeat-count-mobile, calc(100% / $repeat-count-mobile - $grid-gap * $repeat-count-mobile));
    }
}

.containerTitle {
    text-transform: uppercase;
    white-space: pre-line;
    margin: 0;
    padding-top: 4px;
    & h1 {
        margin-top: 20px;
        @include breakpoint('l') {
            font-size: 54px;
        }

    }
}
