@import 'definitions';

$border-radius: 8px;

.linkCard {
	border-radius: $border-radius;
    box-shadow: 0 1px 14px -1px rgb(0 0 0 / 14%);

	& > a {
		display: flex;
		flex-direction: column;
		align-items: center;
		position: relative;
		height: 100%;
	}
	&.static {
		height: 225px;
		width: 300px;
		.imgContainer {
			object-fit: cover;
		}
		@include device('mobile') {
			height: 188px;
			width: 250px;
		}
	}
	&.flex {
		width: 100%;
	}
	&:hover {
		box-shadow: 0 0 8px 4px rgba(0,0,0,0.3);
	}
}

.imgContainer {
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
	width: 100%;
	height: 100%;
}

.flexImgContainer {
	height: 0;
	padding-bottom: 66.66%;
	position: relative;

	img {
		position: absolute;
		object-fit: cover;
	}
}

.flexCardContainer {
	border-radius: $border-radius;
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 100%;
}

.imageSize {
	img {
		border-top-left-radius: $border-radius;
		border-top-right-radius: $border-radius;
		object-fit: cover;
		height: 100%;
		width: 100%;

	}
}

.staticCardWithoutContent {
	width: 100%;
	height: 100%;
}

.staticImgContainer {
	width: 100%;
	min-height: 73%;
}

.staticImgContainerSmall {
	height: 60%;
}

.staticImgWithoutText {
	border-radius: $border-radius;
}

.staticCardFooter {
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border-radius: $border-radius;
	margin: 0px;
}

.cardFooter {
    margin-top: 4px;
	margin-bottom: 0px;
	border-radius: $border-radius;
}

.linkText {
    color: $orange;
	margin-top: 4px;
    margin-bottom: 8px;
	text-align: center;
}

.title {
	font-family: SkattaSansUI-Medium;
	padding: 0px 8px;
	margin: 0;
	font-size: 1.125rem;
	text-align: center;
	color: $grey-80;
}
