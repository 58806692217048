@import 'definitions';

$bottomMargin: 1rem;
.contentByIdContainer {
    margin: 0 1rem;
    & > * {
        margin-bottom: $bottomMargin;
    }
    @include device('tablet') {
        margin: 0 0.5rem;
    }
}
