@import 'definitions';

.bodyContainer {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    margin: 0 1rem;

    & > h2 {
        align-self: stretch;
    }

    @include device('tablet') {
        margin: 0;
    }
}

.filterBar {
    margin-top: 40px;
}

.searchContainer {
    width: 85%;
    max-width: 520px;
}

.loading {
    @include --loadingIndicatorWithScroll;
}
