@import 'definitions';

.productBrandsCollection {
    max-width: 90%;
    margin: 0 auto;
}

.productBrandsCollectionHeading {
    margin-bottom: 1rem;
}

.productBrandsMonthlyCollectionHeading {
    margin: 1rem 0;
    @include breakpoint('m') {
        margin-bottom: 2rem;
    }
}

@media only screen and (max-width: 860px) {
    .productBrandsCollection {
        max-width: unset;
        margin: 0 0.5rem;
    }
}

.loadingIndicator {
    @include --loadingIndicatorWithScroll;
}
