@import 'definitions';

.loading {
    @include --loadingIndicatorWithScroll;
}

.loadMoreButton {
    margin-top: 1rem;
}

.productBrand {
    padding: 0;
}

.productBrand__hero {
    position: relative;
    padding: 1rem;
    height: 500px;
    display: flex;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
}

.productBrand__hero__heading {
    width: 100%;
    margin: 0;
    align-self: flex-end;
    justify-self: center;
    color: white;
}

.productBrand__hero__backButton {
    position: absolute;
    border: none;
    background-color: rgba(0,0,0,0.4);
    border-radius: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.25rem 0.5rem;
    align-self: flex-start;
}

.productBrand__hero__backButton__name {
    color: white;
    font-size: 18px;
}

.productBrand__hero__backButton:hover {
    background-color: rgba(0,0,0,0.6);
    cursor: pointer;
}

.productBrand__hero__backButton > svg {
    width: 24px;
    height: 24px;
}

.productBrand__container {
    display: flex;
    padding: 2rem;
}

.productBrand__products {
    box-sizing: border-box;
    width: 75%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.productBrand__products__productCategoryDropdownContainer {
    width: 100%;
    z-index: 5;
    margin-bottom: 1rem;
}

.productBrand__description {
    box-sizing: border-box;
    width: 25%;
    padding: 0 2rem 0 0;
}

@media only screen and (max-width: 860px) {
    // Redundant
    .productBrand {
        padding: 0;
    }

    .productBrand__hero {
        max-height: 250px;
    }

    .productBrand__container {
        flex-direction: column;
        padding: 1rem;
    }

    .productBrand__description {
        width: 100%;
        padding: 0;
        padding-bottom: 1rem;
        border-bottom: 1px dashed rgb(217, 217, 217);
        margin-bottom: 1rem;
    }

    .productBrand__products {
        width: 100% !important;
    }

    .productBrand__hero__backButton {
        padding: 0.25rem;
    }

    .productBrand__hero__backButton__name {
        display: none;
    }
}
