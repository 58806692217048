@import 'definitions';

.radioGroupContainer {
    width: 100%;
}

.radioGroup__backButton {
    border: none;
    display: flex;
    align-items: center;
    background-color: transparent;
    font-size: 16px;
    margin: 0.25rem 0 0 0;
    padding: 0;
    padding-right: 0.5rem;
}

.radioGroup__backButton > svg {
    padding-left: 0;
}

.radioGroup__backButton:hover {
    background-color: $light-grey2;
    color: $orange;
}

.radioGroup__list {
    list-style: none;
    padding: 0;
    margin: 0;
}

.radioGroup__item {
    border-bottom: 1px solid $light-grey2;
    padding: 0.375rem;
    display: flex;
    align-items: center;
}

.radioGroup__item[data-isselected='true'] {
    background-color: $light-grey2;
}

.radioGroup__item:hover {
    background-color: $light-grey2;
}

.radioGroup__item:last-child {
    border-bottom: none;
}

.radioGroup__item > input {
    margin-right: 0.5rem;
    flex-shrink: 0;
    appearance: none;
    border: 2px solid $medium-grey2;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    position: relative;
    background-color: white;
}

// For mobile filter view
.radioGroup__item[data-isselected='true'] > input {
    border: 2px solid $orange !important;
}
// For mobile filter view
.radioGroup__item[data-isselected='true'] > input::before {
    @include --checkedRadioButton
}

.radioGroup__item > input:checked {
    border: 2px solid $orange;
}

.radioGroup__item > input:checked::before {
    @include --checkedRadioButton
}

.radioGroup__item > label {
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.radioGroup__item > label > span {
    font-family: SkattaSansUI-Medium;
    display: inline-block;
    color: $orange;
    font-size: 0.75rem;
}