@import 'definitions';

.mobile {
    display: flex;

    @include breakpoint('header') {
        display: none;
    }
}

.mobile, .desktop {
    flex-direction: row;
    justify-content: center;
    width: 100%;
    & > * {
        flex-grow: 1;
    }
}

.desktop {
    background-color: $orange;
    display: none;

    @include breakpoint('header') {
        display: flex;
    }
}

.sticky {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 200;
    & + main {
        @include breakpoint('header') {
            margin-top: 0;
        }
        margin-top: $smallHeaderHeight;
    }
    @include breakpoint('header') {
        position: static;
    }
}