@import 'definitions';

.label {
    height: 10px;
    font-family: SkattaSansUI-Regular;
    font-size: 10px;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: 0.5px;
    text-align: left;
    margin-bottom: 6px;
    text-transform: uppercase;

    min-width: 3rem;
}

.lighterText {
    color: #31313d;
}

.labelTextLarge {
    height: $paragraph-font-size;
    font-size: $paragraph-font-size;
}

.normalcase {
    text-transform: none;
}

.normalWeight {
    font-family: SkattaSansUI-Regular;
}

.labeledInput {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
}

.invalid {
    color: $orange;
    text-align: center;
}

.button {
    font-family: SkattaSansUI-Regular;
    font-size: 0.66667rem;
    line-height: 2.33;
    letter-spacing: 0.08333rem;
    text-align: center;
    background-color: transparent;
    color: $orange;
    text-transform: uppercase;
    padding: 3px 20px;
    border-radius: 3px;
    border: solid 1px $orange;
    transition: all .1s ease-in;
    cursor: pointer;
}

.button:hover {
    background-color: $orange;
    color: $white;
}

.alignStart {
    align-items: flex-start;
}
