@import 'definitions';

.labelledButtonBar {
    margin-left: 10px;
    margin-right: 10px;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    width: 120px;
    & > * {
        flex: 0 100%;
    }
}

.label {
    @include --tag;
    flex: 2 0px;
    text-align: center;

    & strong {
        text-transform: uppercase;
    }
}

.buttonbar {
    margin-top: 0.5rem;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: flex-end;

    & > * {
        margin-left: 2px;
        margin-right: 2px;
    }
}
